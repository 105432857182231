import { useEffect } from 'react';
import { cardToFormMap, isCreatedByForms } from '../api/forms';

declare global {
  interface Window {
    TrelloPowerUp: any;
    Trello: any;
    $: any;
    LZUTF8: any;
  }
}

let cardToFormMapPromise: Promise<any> | null = null;

function getAccentColor(t: any) {
  const context = t.getContext();

  const theme = context.theme ?? context.initialTheme ?? 'light';

  if (theme === 'light') {
    return '#FFFFFF';
  }

  return '#1D2125';
}

const initialize = () => {
  window.TrelloPowerUp.initialize(
    {
      'board-buttons': async (t: any) => {
        return [
          {
            text: 'Forms',
            icon: './forms-icon.png',
            callback: async (t: any) => {
              t.modal({
                title: 'Forms',
                url: './forms',
                accentColor: getAccentColor(t),
                height: 600,
                actions: [
                  {
                    icon: './gear.png',
                    alt: 'Settings',
                    position: 'left',
                    callback: async (y: any) => {
                      y.popup({
                        title: 'Forms - Settings',
                        items: [
                          {
                            text: 'Manage subscription...',
                            callback: async (x: any) => {
                              return x.modal({
                                title: 'Forms - Subscription',
                                url: './payment',
                                fullscreen: true,
                              });
                            },
                          },
                          {
                            text: 'Give us feedback...',
                            callback: (x: any) => {
                              x.popup({
                                title: 'Give us feedback',
                                url: './feedback',
                                height: 350,
                              });
                            },
                          },
                        ],
                      });
                    },
                  },
                ],
              });
            },
          },
        ];
      },

      'card-detail-badges': async (t: any) => {
        const cardId = await t.getContext().card;
        const createdByForms = await isCreatedByForms(t, cardId);

        if (!createdByForms) {
          return [];
        }

        if (!cardToFormMapPromise) {
          cardToFormMapPromise = cardToFormMap(t);
        }

        let map = await cardToFormMapPromise;
        let form = map[cardId];

        if (!form && createdByForms) {
          cardToFormMapPromise = cardToFormMap(t);
          map = await cardToFormMapPromise;
          form = map[cardId];
        }

        if (!form) {
          return [];
        }

        return [
          {
            title: 'Form',
            text: form.name,
            callback: async (t: any) => {
              t.modal({
                title: 'Forms',
                url: `./form?formId=${form.id}`,
                accentColor: getAccentColor(t),
                fullscreen: true,
              });
            },
          },
        ];
      },

      'show-settings': async (t: any) => {
        const isAuthorized: boolean = await t.getRestApi().isAuthorized();

        if (!isAuthorized) {
          t.popup({
            title: 'Forms - Settings',
            items: [
              {
                text: 'Authorize',
                callback: (x: any) => {
                  x.popup({
                    title: 'Authorize Forms',
                    url: './authorize',
                    height: 260,
                  });
                },
              },
            ],
          });

          return;
        }

        t.popup({
          title: 'Forms - Settings',
          items: [
            {
              text: 'Manage subscription...',
              callback: async (x: any) => {
                return x.modal({
                  title: 'Forms - Subscription',
                  url: './payment',
                  fullscreen: true,
                });
              },
            },
          ],
        });
      },
    },
    {
      appKey: '5549551b9ed7f39a02e5918b5b01b24c',
      appName: 'Forms',
    }
  );
};

export const IframeConnector = () => {
  useEffect(() => {
    initialize();
  }, []);

  return <div>ok</div>;
};
