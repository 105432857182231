import styles from './Fields.module.scss';
import Button from '@atlaskit/button';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TextInput from '@atlaskit/textfield';
import Textarea from '@atlaskit/textarea';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Checkbox from '@atlaskit/checkbox';
import { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { MembersField as MembersFieldType } from '../../api/types';
import { TrelloPowerUpContext } from '@powerupsclub/trello';
import Select from '@atlaskit/select';
import { FormPageContext } from '../FormsModal/FormPage';
import { ConditionalSelection } from './ConditionalSelection';

type MembersFieldProps = {
  field: MembersFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: MembersFieldType) => void;
  onRemove: () => void;
};

const styleOptions = [
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
];

export type Member = {
  id: string;
  fullName: string;
  username: string;
  avatarUrl: string;
};

export const MembersField: React.FC<MembersFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  const { trelloIframe } = useContext(TrelloPowerUpContext);
  const [expanded, setExpanded] = useState(defaultExpanded);

  const [members, setMembers] = useState<Member[]>([]);

  const { bump } = useContext(FormPageContext);

  useEffect(() => {
    bump();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  useEffect(() => {
    async function loadMembers() {
      const _board = await trelloIframe.board('members');
      setMembers(_board.members);
    }
    loadMembers();
  }, [trelloIframe]);

  const missingName = field.label.length === 0;

  return (
    <div
      id={field.id}
      className={cx(styles.fieldContainer, {
        [styles.collapsed]: !expanded,
      })}
    >
      <div className={styles.fieldHeader}>
        {expanded ? (
          <>
            <PeopleIcon label="members" />
            <TextInput
              isInvalid={missingName}
              placeholder="Field name"
              value={field.label}
              onChange={(e) => {
                onChange({
                  ...field,
                  label: e.currentTarget.value,
                });
              }}
            />
          </>
        ) : (
          <FormMembersField members={members} field={field} isPreview />
        )}

        {!expanded && (
          <div className={styles.editActions}>
            <Button
              appearance="subtle"
              spacing="compact"
              isDisabled={missingName}
              onClick={() => setExpanded(!expanded)}
              iconBefore={<EditIcon size="small" label="edit" />}
            />

            <Button
              appearance="subtle"
              spacing="compact"
              iconBefore={<EditorCloseIcon label="remove" />}
              onClick={(e) => {
                e.stopPropagation();
                trelloIframe.popup({
                  mouseEvent: e,
                  title: 'Remove field',
                  type: 'confirm',
                  message:
                    'Are you sure you want to remove this field? There is no undo.',
                  confirmText: 'Remove',
                  confirmStyle: 'danger',
                  onConfirm: (t: any) => {
                    onRemove();
                    t.closePopup();
                  },
                });
              }}
            />
          </div>
        )}
      </div>

      {expanded && (
        <>
          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Description</div>
            <Textarea
              placeholder="Description"
              value={field.description}
              onChange={(e) => {
                onChange({
                  ...field,
                  description: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Style</div>
            <Select
              options={styleOptions}
              value={
                styleOptions.find((o) => o.value === field.style) ||
                styleOptions[0]
              }
              onChange={(o: any) => {
                onChange({
                  ...field,
                  style: o.value,
                });
              }}
            ></Select>
          </div>

          {field.style === 'dropdown' && (
            <div className={styles.fieldSection}>
              <div className={styles.editLabel}>Placeholder</div>
              <TextInput
                placeholder="Placeholder"
                value={field.placeholder}
                onChange={(e) => {
                  onChange({
                    ...field,
                    placeholder: e.currentTarget.value,
                  });
                }}
              />
            </div>
          )}

          <div className={styles.fieldSection}>
            <Checkbox
              label="Only show certain members"
              isChecked={field.filterMembers}
              onChange={(e) => {
                const idMembers = field.idMembers || members.map((l) => l.id);

                onChange({
                  ...field,
                  filterMembers: e.currentTarget.checked,
                  idMembers,
                });
              }}
            />
            {field.filterMembers && (
              <Select
                isMulti
                options={members.map((m) => ({
                  label: m.fullName,
                  value: m.id,
                }))}
                value={field.idMembers?.map((id) => ({
                  label: members.find((m) => m.id === id)?.fullName,
                  value: id,
                }))}
                onChange={(o: any) => {
                  onChange({
                    ...field,
                    idMembers: o.map((o: any) => o.value),
                  });
                }}
              />
            )}
          </div>

          <div className={styles.fieldSection}>
            <Checkbox
              label="This field is required"
              isChecked={field.required}
              onChange={(e) => {
                onChange({
                  ...field,
                  required: e.currentTarget.checked,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <Checkbox
              label="Allow user to select multiple members"
              isChecked={field.multiselect}
              onChange={(e) => {
                onChange({
                  ...field,
                  multiselect: e.currentTarget.checked,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <ConditionalSelection
              field={field}
              onChange={(conditional) => {
                onChange({
                  ...field,
                  conditional,
                });
              }}
            />
          </div>
        </>
      )}
      <div className={styles.fieldIsLinkedToText}>
        This field is linked to the card {field.targetField}.
      </div>

      {expanded && (
        <div className={styles.expandEditActions}>
          <Button
            appearance="primary"
            isDisabled={missingName}
            onClick={() => setExpanded(false)}
          >
            Done
          </Button>

          <Button
            appearance="subtle"
            onClick={(e) => {
              e.stopPropagation();
              trelloIframe.popup({
                mouseEvent: e,
                title: 'Remove field',
                type: 'confirm',
                message:
                  'Are you sure you want to remove this field? There is no undo.',
                confirmText: 'Remove',
                confirmStyle: 'danger',
                onConfirm: (t: any) => {
                  onRemove();
                  t.closePopup();
                },
              });
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

type FormMembersFieldProps = {
  field: MembersFieldType;
  isPreview?: boolean;
  members: Member[];
  fieldProps?: any;
};

export const FormMembersField: React.FC<FormMembersFieldProps> = ({
  field,
  members,
  isPreview,
  fieldProps,
}) => {
  const options = members
    .filter((l) => {
      if (field.filterMembers) {
        return field.idMembers?.includes(l.id);
      }

      return true;
    })
    .map((l) => ({
      value: l.id,
      label: l.fullName || l.username,
    }));

  return (
    <div className={styles.formFieldContainer}>
      <div className={cx(styles.label, { [styles.isPreview]: isPreview })}>
        {field.label}
      </div>
      {field.description && (
        <div className={styles.description}>{field.description}</div>
      )}

      {field.style === 'dropdown' ? (
        <Select
          options={options}
          isClearable={!field.required}
          isMulti={field.multiselect}
          placeholder={field.placeholder}
          {...(fieldProps ? fieldProps : {})}
        />
      ) : (
        <div>
          {options.map((o) => {
            return (
              <Checkbox
                key={o.value}
                label={o.label}
                {...(fieldProps
                  ? {
                      isChecked: fieldProps?.value?.includes(o.value) ?? false,
                      onChange: (e) => {
                        let newCheckedState = fieldProps.value
                          ? [...fieldProps.value]
                          : [];
                        if (field.multiselect) {
                          if (e.currentTarget.checked) {
                            newCheckedState.push(o.value);
                          } else {
                            newCheckedState = newCheckedState.filter(
                              (c) => c !== o.value
                            );
                          }
                        } else {
                          newCheckedState = [o.value];
                        }

                        if (fieldProps.onChange) {
                          fieldProps.onChange(newCheckedState);
                        }
                      },
                    }
                  : {})}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
