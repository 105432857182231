import styles from './ExternalForm.module.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import Button, { LoadingButton } from '@atlaskit/button';
import { FormTextField } from '../Fields/TextField';
import { Form as FormType } from '../../api/types';
import { getForm, submitMultipartForm } from '../../api/externalForms';
import { FormNumberField } from '../Fields/NumberField';
import { FormLabelsField } from '../Fields/LabelsField';
import { FormAttachmentsField } from '../Fields/AttachmentsField';
import { FormDropdownField } from '../Fields/DropdownField';
import { FormMembersField } from '../Fields/MembersField';
import { FormCheckboxField } from '../Fields/CheckboxField';
import { FormDateField } from '../Fields/DateField';

import WarningIcon from '@atlaskit/icon/glyph/warning';
import Banner from '@atlaskit/banner';

import { LoggerErrorBoundary } from '@powerupsclub/logger';

import Form, { Field } from '@atlaskit/form';
import { shouldAppear } from '../../api/conditionals';

export const ExternalForm: React.FC = () => {
  return (
    <LoggerErrorBoundary powerUpKey="forms-external">
      <Router>
        <Switch>
          <Route exact path={['/:id/:slug', '/:id']}>
            <ExternalFormPage />
          </Route>

          <Route path="*">
            <div>not found</div>
          </Route>
        </Switch>
      </Router>
    </LoggerErrorBoundary>
  );
};

export const ExternalFormPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [form, setForm] = useState<FormType | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [idCard, setIdCard] = useState<string | null>(null);

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      const _form = await getForm(id);

      if (!_form) {
        setError('This form does not exist.');
        return;
      }

      setForm(_form);

      setIsLoading(false);
    }

    load();
  }, [id]);

  const [, setLastChangeTime] = useState(new Date().toISOString());

  if (isLoading) {
    return (
      <div className={styles.loadingSpinnerContainer}>
        <Spinner size="large" />
      </div>
    );
  }

  if (!form) {
    return <div>{error}</div>;
  }

  const showPayments = !form.hasSubscription && form.trialHasEnded;

  return (
    <>
      {showPayments && (
        <Banner
          appearance="warning"
          icon={<WarningIcon label="" secondaryColor="inherit" />}
        >
          Payment details needed. This form has been disabled because the
          creator has not added payment details.{' '}
          <a href="https://tinypowerups.com/manage-subscription">
            Add payment details
          </a>
        </Banner>
      )}
      <div
        style={{
          backgroundColor: form.backgroundColor ?? undefined,
          ...(form.unsplashBackground
            ? {
                backgroundImage: `url(${form.unsplashBackground})`,
                backgroundSize: 'cover',
              }
            : {}),
          paddingTop: '16px',
          paddingBottom: '16px',
          minHeight: 'calc(100vh - 32px)',
          width: '100%',
        }}
      >
        <div className={styles.mainContainer}>
          <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            {form.logo && (
              <div
                style={{
                  backgroundImage: `url(${form.logo})`,
                }}
                className={styles.logo}
              />
            )}
            <h1>{form.name}</h1>
          </div>
          {!hasSubmitted && (
            <div className={styles.introductionMessage}>
              {form.introductionMessage}
            </div>
          )}

          {!hasSubmitted && (
            <Form
              isDisabled={showPayments}
              onSubmit={async (values: any) => {
                setIsSubmitting(true);
                const requiredFields = form.fields.filter((field) => {
                  if (field.conditional) {
                    const dependentField = form.fields.find(
                      (f) => f.id === field.conditional?.idField
                    );

                    if (!dependentField) {
                      return field.required;
                    }

                    let value = values[dependentField.id];

                    if (
                      (dependentField.type === 'dropdown' ||
                        dependentField.type === 'members' ||
                        dependentField.type === 'labels') &&
                      value
                    ) {
                      if (Array.isArray(value)) {
                        value = value.map((v: any) => v.value);
                      }

                      value = value.value;
                    }

                    return (
                      shouldAppear(field, {
                        ...dependentField,
                        value,
                      }) && field.required
                    );
                  }

                  return field.required;
                });

                const missingRequiredFields = requiredFields.filter(
                  (field) => !values[field.id]
                );

                if (missingRequiredFields.length) {
                  const errors: any = {};

                  missingRequiredFields.forEach((field) => {
                    errors[field.id] = 'This field is required.';
                  });

                  setIsSubmitting(false);

                  return errors;
                }

                const formData = new FormData();

                Object.entries(values).forEach(([key, value]) => {
                  if (value) {
                    if (key === 'attachments' && Array.isArray(value)) {
                      value.forEach((v, index) =>
                        formData.append(`${key}[${index}]`, v)
                      );
                    } else if (
                      typeof value === 'object' ||
                      Array.isArray(value)
                    ) {
                      formData.append(key, JSON.stringify(value));
                    } else {
                      formData.append(key, value as any);
                    }
                  }
                });

                const response = await submitMultipartForm(form.id, formData);
                setHasSubmitted(true);
                setIdCard(response.idCard);
                setIsSubmitting(false);
              }}
            >
              {({ formProps, getValues }) => (
                <form className={styles.form} {...formProps}>
                  {form.fields
                    .filter((field) => {
                      if (field.conditional) {
                        const dependentField = form.fields.find(
                          (f) => f.id === field.conditional?.idField
                        );

                        const currentValues = getValues();

                        if (dependentField) {
                          let value = currentValues[dependentField.id];

                          if (
                            (dependentField.type === 'dropdown' ||
                              dependentField.type === 'members' ||
                              dependentField.type === 'labels') &&
                            value
                          ) {
                            if (Array.isArray(value)) {
                              value = value.map((v: any) => v.value);
                            }

                            value = value.value;
                          }

                          return shouldAppear(field, {
                            ...dependentField,
                            value,
                          });
                        }
                      }

                      return true;
                    })
                    .map((field) => {
                      switch (field.type) {
                        case 'text':
                          return (
                            <Field
                              key={field.id}
                              name={field.id}
                              isRequired={field.required}
                            >
                              {({ fieldProps }) => {
                                const originalOnChange = fieldProps.onChange;
                                fieldProps.onChange = (e: any) => {
                                  originalOnChange(e);
                                  setLastChangeTime(new Date().toISOString());
                                };

                                return (
                                  <FormTextField
                                    field={field}
                                    fieldProps={fieldProps}
                                  />
                                );
                              }}
                            </Field>
                          );
                        case 'date':
                          return (
                            <Field
                              key={field.id}
                              name={field.id}
                              isRequired={field.required}
                            >
                              {({ fieldProps }) => {
                                const originalOnChange = fieldProps.onChange;
                                fieldProps.onChange = (e: any) => {
                                  originalOnChange(e);
                                  setLastChangeTime(new Date().toISOString());
                                };

                                return (
                                  <FormDateField
                                    field={field}
                                    fieldProps={fieldProps}
                                  />
                                );
                              }}
                            </Field>
                          );
                        case 'number':
                          return (
                            <Field
                              key={field.id}
                              name={field.id}
                              isRequired={field.required}
                            >
                              {({ fieldProps }) => {
                                const originalOnChange = fieldProps.onChange;
                                fieldProps.onChange = (e: any) => {
                                  originalOnChange(e);
                                  setLastChangeTime(new Date().toISOString());
                                };

                                return (
                                  <FormNumberField
                                    field={field}
                                    fieldProps={fieldProps}
                                  />
                                );
                              }}
                            </Field>
                          );
                        case 'dropdown':
                          return (
                            <Field
                              key={field.id}
                              name={field.id}
                              isRequired={field.required}
                            >
                              {({ fieldProps, error }) => {
                                const originalOnChange = fieldProps.onChange;
                                fieldProps.onChange = (e: any) => {
                                  originalOnChange(e);
                                  setLastChangeTime(new Date().toISOString());
                                };

                                return (
                                  <>
                                    <FormDropdownField
                                      field={field as any}
                                      fieldProps={fieldProps}
                                    />
                                    <div className={styles.fieldError}>
                                      {error}
                                    </div>
                                  </>
                                );
                              }}
                            </Field>
                          );
                        case 'labels':
                          return (
                            <Field
                              key={field.id}
                              name={field.id}
                              isRequired={field.required}
                            >
                              {({ fieldProps, error }) => {
                                const originalOnChange = fieldProps.onChange;
                                fieldProps.onChange = (e: any) => {
                                  originalOnChange(e);
                                  setLastChangeTime(new Date().toISOString());
                                };

                                return (
                                  <>
                                    <FormLabelsField
                                      field={field as any}
                                      labels={(field as any).labels}
                                      fieldProps={fieldProps}
                                    />
                                    <div className={styles.fieldError}>
                                      {error}
                                    </div>
                                  </>
                                );
                              }}
                            </Field>
                          );
                        case 'members':
                          return (
                            <Field
                              key={field.id}
                              name={field.id}
                              isRequired={field.required}
                            >
                              {({ fieldProps, error }) => {
                                const originalOnChange = fieldProps.onChange;
                                fieldProps.onChange = (e: any) => {
                                  originalOnChange(e);
                                  setLastChangeTime(new Date().toISOString());
                                };

                                return (
                                  <>
                                    <FormMembersField
                                      field={field as any}
                                      fieldProps={fieldProps}
                                      members={(field as any).members}
                                    />
                                    <div className={styles.fieldError}>
                                      {error}
                                    </div>
                                  </>
                                );
                              }}
                            </Field>
                          );
                        case 'attachments':
                          return (
                            <Field
                              key={field.id}
                              name={field.id}
                              isRequired={field.required}
                            >
                              {({ fieldProps, error }) => {
                                const originalOnChange = fieldProps.onChange;
                                fieldProps.onChange = (e: any) => {
                                  originalOnChange(e);
                                  setLastChangeTime(new Date().toISOString());
                                };

                                return (
                                  <>
                                    <FormAttachmentsField
                                      field={field as any}
                                      fieldProps={fieldProps}
                                    />
                                    <div className={styles.fieldError}>
                                      {error}
                                    </div>
                                  </>
                                );
                              }}
                            </Field>
                          );
                        case 'checkbox':
                          return (
                            <Field
                              key={field.id}
                              name={field.id}
                              isRequired={field.required}
                            >
                              {({ fieldProps, error }) => {
                                const originalOnChange = fieldProps.onChange;
                                fieldProps.onChange = (e: any) => {
                                  originalOnChange(e);
                                  setLastChangeTime(new Date().toISOString());
                                };

                                return (
                                  <>
                                    <FormCheckboxField
                                      field={field as any}
                                      fieldProps={fieldProps}
                                    />
                                    <div className={styles.fieldError}>
                                      {error}
                                    </div>
                                  </>
                                );
                              }}
                            </Field>
                          );
                        default:
                          return null;
                      }
                    })}

                  <div style={{ marginTop: '8px' }}>
                    <LoadingButton
                      isDisabled={showPayments}
                      isLoading={isSubmitting}
                      type="submit"
                      appearance="primary"
                      shouldFitContainer
                    >
                      {form.submitButtonText || 'Submit'}
                    </LoadingButton>
                  </div>
                </form>
              )}
            </Form>
          )}

          {hasSubmitted && (
            <div>
              <div className={styles.confirmationMessage}>
                {form.confirmationMessage ||
                  'Form submitted successfully. Thank you.'}
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  paddingTop: '8px',
                }}
              >
                {form.showViewInTrelloButton && (
                  <Button
                    shouldFitContainer
                    appearance="primary"
                    href={`https://trello.com/c/${idCard}`}
                  >
                    View in Trello
                  </Button>
                )}
                {form.allowMultipleSubmissions && (
                  <Button
                    shouldFitContainer
                    onClick={(e) => {
                      window.location.reload();
                    }}
                  >
                    Submit another response
                  </Button>
                )}
              </div>
            </div>
          )}

          <div>
            <div className={styles.poweredBy}>
              Powered by{' '}
              <a
                href="https://trello.com/power-ups/6381377e7e70e001b13e8e9e/forms-for-trello"
                target="_blank"
                rel="noreferrer"
              >
                Forms for Trello
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
