import styles from './LabelSelect.module.scss';
import { CheckboxSelect } from '@atlaskit/select';
import { useMemo } from 'react';

const LABEL_COLOR_MAP: any = {
  green_light: '#eef6ec',
  green: '#d6ecd2',
  green_dark: '#b7ddb0',
  yellow_light: '#fdfae5',
  yellow: '#faf3c0',
  yellow_dark: '#f5ea92',
  orange_light: '#fdf4e7',
  orange: '#fce6c6',
  orange_dark: '#fad29c',
  red_light: '#fbedeb',
  red: '#f5d3ce',
  red_dark: '#efb3ab',
  purple_light: '#f7f0fa',
  purple: '#eddbf4',
  purple_dark: '#dfc0eb',
  blue_light: '#e4f0f6',
  blue: '#bcd9ea',
  blue_dark: '#8bbdd9',
  sky_light: '#e4f7fa',
  sky: '#bdecf3',
  sky_dark: '#8fdfeb',
  lime_light: '#ecfbf3',
  lime: '#d3f6e4',
  lime_dark: '#b3f1d0',
  pink_light: '#fef1f9',
  pink: '#fcdcef',
  pink_dark: '#f9c2e4',
  black_light: '#ebecf0',
  black: '#dfe1e6',
  black_dark: '#c1c7d0',
  gray: '#868e96',
};

const LABEL_DOT_COLOR_MAP: any = {
  green_light: '#b7ddb0',
  green: '#7bc86c',
  green_dark: '#5aac44',
  yellow_light: '#f5ea92',
  yellow: '#f5dd29',
  yellow_dark: '#e6c60d',
  orange_light: '#fad29c',
  orange: '#ffaf3f',
  orange_dark: '#e79217',
  red_light: '#efb3ab',
  red: '#ef7564',
  red_dark: '#cf513d',
  purple_light: '#dfc0eb',
  purple: '#cd8de5',
  purple_dark: '#a86cc1',
  blue_light: '#8bbdd9',
  blue: '#5ba4cf',
  blue_dark: '#026aa7',
  sky_light: '#8fdfeb',
  sky: '#29cce5',
  sky_dark: '#00aecc',
  lime_light: '#b3f1d0',
  lime: '#6deca9',
  lime_dark: '#4ed583',
  pink_light: '#f9c2e4',
  pink: '#ff8ed4',
  pink_dark: '#e568af',
  black_light: '#505f79',
  black: '#344563',
  black_dark: '#091e42',
  gray: '#868e96',
};

const LABEL_HOVER_COLOR_MAP: any = {
  green_light: '#d6ecd2',
  green: '#b7ddb0',
  green_dark: '#99d18f',
  yellow_light: '#faf3c0',
  yellow: '#f5ea92',
  yellow_dark: '#f3e260',
  orange_light: '#fce6c6',
  orange: '#fad29c',
  orange_dark: '#fdbf69',
  red_light: '#f5d3ce',
  red: '#efb3ab',
  red_dark: '#ec9488',
  purple_light: '#eddbf4',
  purple: '#dfc0eb',
  purple_dark: '#d5a6e6',
  blue_light: '#bcd9ea',
  blue: '#8bbdd9',
  blue_dark: '#5ba4cf',
  sky_light: '#bdecf3',
  sky: '#8fdfeb',
  sky_dark: '#5dd3e5',
  lime_light: '#d3f6e4',
  lime: '#b3f1d0',
  lime_dark: '#90ecc1',
  pink_light: '#fcdcef',
  pink: '#f9c2e4',
  pink_dark: '#ffabdf',
  black_light: '#dfe1e6',
  black: '#c1c7d0',
  black_dark: '#b3bac5',
  gray: '#c1c7d0',
};

export type Label = {
  id: string;
  name: string;
  color: string;
};

export type LabelSelectProps = {
  labels: Label[];
  selectedIdLabels: string[];
  selectedIdLabelsChange: (selectedIdLabels: string[]) => void;
};

export const LabelSelect: React.FC<LabelSelectProps> = ({
  labels,
  selectedIdLabels,
  selectedIdLabelsChange,
}) => {
  const labelsOptions = useMemo(() => {
    return labels.map((label) => {
      return {
        value: label.id,
        label: label.name,
        color: label.color,
      };
    });
  }, [labels]);

  return (
    <div className={styles.selectContainer}>
      <CheckboxSelect
        className={styles.select}
        value={selectedIdLabels.map((id: string) => {
          const thisLabel = labels.find((label) => label.id === id)!;
          return {
            label: thisLabel.name,
            value: thisLabel.id,
            name: thisLabel.name,
            color: thisLabel.color,
          };
        })}
        onChange={(values) => {
          const newIdLabels = values.map((value: any) => value.value);
          selectedIdLabelsChange(newIdLabels);
        }}
        options={labelsOptions}
        maxMenuHeight={200}
        styles={{
          option: (provided, state) => {
            return {
              ...provided,
              'div:nth-child(2)': {
                position: 'relative',
                backgroundColor:
                  LABEL_COLOR_MAP[state.data.color as any] || '#ffffff',
                borderRadius: '3px',
                height: '32px',
                fontWeight: 400,
                width: '95%',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',

                color: '#172b4d',
                padding: '0px 12px',
                lineHeight: '32px',

                paddingLeft:
                  state.data.color === 'none' || state.data.color == null
                    ? undefined
                    : '32px',

                '&::before':
                  state.data.color === 'none' || state.data.color == null
                    ? undefined
                    : {
                        content: "''",
                        position: 'absolute',
                        top: '8px',
                        bottom: '8px',
                        left: '8px',
                        borderRadius: '50%',
                        width: '16px',
                        height: '16px',
                        backgroundColor:
                          LABEL_DOT_COLOR_MAP[state.data.color as any],
                      },
              },
            };
          },
          multiValueLabel: (provided, state) => {
            return {
              ...provided,
              position: 'relative',
              minWidth: '56px',
              color: '#172b4d',
              borderRadius: '2px 0 0 2px',
              backgroundColor:
                LABEL_COLOR_MAP[state.data.color as any] || '#ffffff',

              paddingLeft:
                state.data.color === 'none' || state.data.color == null
                  ? undefined
                  : '16px',

              '&::before':
                state.data.color === 'none' || state.data.color == null
                  ? undefined
                  : {
                      content: "''",
                      position: 'absolute',
                      top: '5px',
                      bottom: '4px',
                      left: '4px',
                      borderRadius: '50%',
                      width: '8px',
                      height: '8px',
                      backgroundColor:
                        LABEL_DOT_COLOR_MAP[state.data.color as any],
                    },
            };
          },
          multiValueRemove: (provided, state) => {
            return {
              ...provided,
              fill: '#172b4d',
              backgroundColor:
                LABEL_COLOR_MAP[state.data.color as any] || '#fff',
              '&:hover': {
                backgroundColor:
                  LABEL_HOVER_COLOR_MAP[state.data.color as any] || '#eee',
              },
            };
          },
        }}
      />
    </div>
  );
};
