import styles from './Fields.module.scss';
import Button from '@atlaskit/button';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TextInput from '@atlaskit/textfield';
import Textarea from '@atlaskit/textarea';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Checkbox from '@atlaskit/checkbox';
import { useCallback, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { DateField as DateFieldType } from '../../api/types';
import { TrelloPowerUpContext } from '@powerupsclub/trello';
import { FormPageContext } from '../FormsModal/FormPage';
import { ConditionalSelection } from './ConditionalSelection';

type DateFieldProps = {
  field: DateFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: DateFieldType) => void;
  onRemove: () => void;
};

export const DateField: React.FC<DateFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  const { trelloIframe } = useContext(TrelloPowerUpContext);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { bump } = useContext(FormPageContext);

  useEffect(() => {
    bump();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const missingName = field.label.length === 0;

  return (
    <div
      id={field.id}
      className={cx(styles.fieldContainer, {
        [styles.collapsed]: !expanded,
      })}
    >
      <div className={styles.fieldHeader}>
        {expanded ? (
          <>
            <CalendarIcon label="date" />
            <TextInput
              isInvalid={missingName}
              placeholder="Field name"
              value={field.label}
              onChange={(e) => {
                onChange({
                  ...field,
                  label: e.currentTarget.value,
                });
              }}
            />
          </>
        ) : (
          <FormDateField field={field} isPreview />
        )}

        {!expanded && (
          <div className={styles.editActions}>
            <Button
              appearance="subtle"
              spacing="compact"
              isDisabled={missingName}
              onClick={() => setExpanded(!expanded)}
              iconBefore={<EditIcon size="small" label="edit" />}
            />

            <Button
              appearance="subtle"
              spacing="compact"
              iconBefore={<EditorCloseIcon label="remove" />}
              onClick={(e) => {
                e.stopPropagation();
                trelloIframe.popup({
                  mouseEvent: e,
                  title: 'Remove field',
                  type: 'confirm',
                  message:
                    'Are you sure you want to remove this field? There is no undo.',
                  confirmText: 'Remove',
                  confirmStyle: 'danger',
                  onConfirm: (t: any) => {
                    onRemove();
                    t.closePopup();
                  },
                });
              }}
            />
          </div>
        )}
      </div>

      {expanded && (
        <>
          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Description</div>
            <Textarea
              placeholder="Description"
              value={field.description}
              onChange={(e) => {
                onChange({
                  ...field,
                  description: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <Checkbox
              label="This field is required"
              isChecked={field.required}
              onChange={(e) => {
                onChange({
                  ...field,
                  required: e.currentTarget.checked,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <ConditionalSelection
              field={field}
              onChange={(conditional) => {
                onChange({
                  ...field,
                  conditional,
                });
              }}
            />
          </div>
        </>
      )}
      <div className={styles.fieldIsLinkedToText}>
        This field is linked to the card{' '}
        {(() => {
          if (field.targetField === 'dueDate') return 'due date';
          else if (field.targetField === 'startDate') return 'start date';
          else return field.targetField;
        })()}
      </div>

      {expanded && (
        <div className={styles.expandEditActions}>
          <Button
            appearance="primary"
            isDisabled={missingName}
            onClick={() => setExpanded(false)}
          >
            Done
          </Button>

          <Button
            appearance="subtle"
            onClick={(e) => {
              e.stopPropagation();
              trelloIframe.popup({
                mouseEvent: e,
                title: 'Remove field',
                type: 'confirm',
                message:
                  'Are you sure you want to remove this field? There is no undo.',
                confirmText: 'Remove',
                confirmStyle: 'danger',
                onConfirm: (t: any) => {
                  onRemove();
                  t.closePopup();
                },
              });
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

type FormDateFieldProps = {
  field: DateFieldType;
  isPreview?: boolean;
  fieldProps?: any;
};

export const FormDateField: React.FC<FormDateFieldProps> = ({
  field,
  isPreview,
  fieldProps,
}) => {
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');

  const onChangeDate = useCallback(
    (newDate: string) => {
      setDate(newDate);

      if (field.id === 'startDate' || !time.length) {
        if (newDate) {
          fieldProps.onChange(new Date(newDate).toISOString());
        }
      } else {
        fieldProps.onChange(new Date(`${newDate}T${time}`).toISOString());
      }
    },
    [field.id, fieldProps, time]
  );

  const onChangeTime = useCallback(
    (newTime: string) => {
      setTime(newTime);

      if (date.length && newTime) {
        fieldProps.onChange(new Date(`${date}T${newTime}`).toISOString());
      } else if (date.length) {
        fieldProps.onChange(new Date(date).toISOString());
      }
    },
    [date, fieldProps]
  );

  return (
    <div className={styles.formFieldContainer}>
      <div className={styles.label}>{field.label}</div>
      {field.description && (
        <div className={styles.description}>{field.description}</div>
      )}

      <div className={styles.dateTimeContainer}>
        <TextInput
          placeholder="Date"
          isReadOnly={isPreview}
          isRequired={field.required}
          {...(fieldProps ? fieldProps : {})}
          type="date"
          value={date}
          onChange={(e) => {
            onChangeDate(e.currentTarget.value);
          }}
        />
        {field.targetField !== 'startDate' && (
          <TextInput
            placeholder="Time"
            isReadOnly={isPreview}
            isRequired={field.required}
            {...(fieldProps ? fieldProps : {})}
            type="time"
            value={time}
            onChange={(e) => {
              onChangeTime(e.currentTarget.value);
            }}
          />
        )}
      </div>

      {/* <div className={styles.calendarContainer}>
        <Calendar />
      </div> */}
    </div>
  );
};
