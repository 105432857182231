import styles from './Fields.module.scss';
import Button from '@atlaskit/button';
import EditorTextStyleIcon from '@atlaskit/icon/glyph/editor/text-style';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TextInput from '@atlaskit/textfield';
import Textarea from '@atlaskit/textarea';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Checkbox from '@atlaskit/checkbox';
import { useContext, useState } from 'react';
import cx from 'classnames';
import { TextField as TextFieldType } from '../../api/types';
import { TrelloPowerUpContext } from '@powerupsclub/trello';
import { ConditionalSelection } from './ConditionalSelection';
import { FormPageContext } from '../FormsModal/FormPage';

type TextFieldProps = {
  field: TextFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: TextFieldType) => void;
  onRemove: () => void;
};

export const TextField: React.FC<TextFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  const { trelloIframe } = useContext(TrelloPowerUpContext);
  const { bump } = useContext(FormPageContext);
  const [expanded, setExpanded] = useState(defaultExpanded);

  const missingName = field.label.length === 0;

  return (
    <div
      id={field.id}
      className={cx(styles.fieldContainer, {
        [styles.collapsed]: !expanded,
      })}
    >
      <div className={styles.fieldHeader}>
        {expanded ? (
          <>
            <EditorTextStyleIcon label="text" />
            <TextInput
              isInvalid={missingName}
              placeholder="Field name"
              value={field.label}
              onChange={(e) => {
                onChange({
                  ...field,
                  label: e.currentTarget.value,
                });
              }}
            />
          </>
        ) : (
          <FormTextField field={field} isPreview />
        )}

        {!expanded && (
          <div className={styles.editActions}>
            <Button
              appearance="subtle"
              spacing="compact"
              isDisabled={missingName}
              onClick={() => {
                setExpanded(!expanded);
                bump();
              }}
              iconBefore={<EditIcon size="small" label="edit" />}
            />

            <Button
              appearance="subtle"
              spacing="compact"
              iconBefore={<EditorCloseIcon label="remove" />}
              onClick={(e) => {
                e.stopPropagation();
                trelloIframe.popup({
                  mouseEvent: e,
                  title: 'Remove field',
                  type: 'confirm',
                  message:
                    'Are you sure you want to remove this field? There is no undo.',
                  confirmText: 'Remove',
                  confirmStyle: 'danger',
                  onConfirm: (t: any) => {
                    onRemove();
                    t.closePopup();
                  },
                });
              }}
            />
          </div>
        )}
      </div>

      {expanded && (
        <>
          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Description</div>
            <Textarea
              placeholder="Description"
              value={field.description}
              onChange={(e) => {
                onChange({
                  ...field,
                  description: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Placeholder</div>
            <TextInput
              placeholder="Placeholder"
              value={field.placeholder}
              onChange={(e) => {
                onChange({
                  ...field,
                  placeholder: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <Checkbox
              label="This field is required"
              isChecked={field.required}
              onChange={(e) => {
                onChange({
                  ...field,
                  required: e.currentTarget.checked,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <Checkbox
              label="This field is multiline (textarea)"
              isChecked={field.multiline}
              onChange={(e) => {
                onChange({
                  ...field,
                  multiline: e.currentTarget.checked,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <ConditionalSelection
              field={field}
              onChange={(conditional) => {
                onChange({
                  ...field,
                  conditional,
                });
              }}
            />
          </div>
        </>
      )}
      <div className={styles.fieldIsLinkedToText}>
        This field is linked to the card {field.targetField}.
      </div>

      {expanded && (
        <div className={styles.expandEditActions}>
          <Button
            appearance="primary"
            isDisabled={missingName}
            onClick={() => {
              setExpanded(false);
              bump();
            }}
          >
            Done
          </Button>

          <Button
            appearance="subtle"
            onClick={(e) => {
              e.stopPropagation();
              trelloIframe.popup({
                mouseEvent: e,
                title: 'Remove field',
                type: 'confirm',
                message:
                  'Are you sure you want to remove this field? There is no undo.',
                confirmText: 'Remove',
                confirmStyle: 'danger',
                onConfirm: (t: any) => {
                  onRemove();
                  t.closePopup();
                },
              });
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

type FormTextFieldProps = {
  field: TextFieldType;
  isPreview?: boolean;
  fieldProps?: any;
};

export const FormTextField: React.FC<FormTextFieldProps> = ({
  field,
  isPreview,
  fieldProps,
}) => {
  return (
    <div className={styles.formFieldContainer}>
      <div className={styles.label}>{field.label}</div>
      {field.description && (
        <div className={styles.description}>{field.description}</div>
      )}
      {field.multiline ? (
        <Textarea
          name={field.id}
          placeholder={field.placeholder}
          isReadOnly={isPreview}
          isRequired={field.required}
          {...(fieldProps ? fieldProps : {})}
        />
      ) : (
        <TextInput
          name={field.id}
          placeholder={field.placeholder}
          isReadOnly={isPreview}
          isRequired={field.required}
          {...(fieldProps ? fieldProps : {})}
        />
      )}
    </div>
  );
};
