import styles from './Fields.module.scss';
import Button from '@atlaskit/button';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TextInput from '@atlaskit/textfield';
import Textarea from '@atlaskit/textarea';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Checkbox from '@atlaskit/checkbox';
import { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { AttachmentsField as AttachmentsFieldType } from '../../api/types';
import { TrelloPowerUpContext } from '@powerupsclub/trello';
import { FileUploader } from 'react-drag-drop-files';
import { FormPageContext } from '../FormsModal/FormPage';
import { ConditionalSelection } from './ConditionalSelection';

type AttachmentsFieldProps = {
  field: AttachmentsFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: AttachmentsFieldType) => void;
  onRemove: () => void;
};

export const AttachmentsField: React.FC<AttachmentsFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  const { trelloIframe } = useContext(TrelloPowerUpContext);
  const { bump } = useContext(FormPageContext);
  const [expanded, setExpanded] = useState(defaultExpanded);

  const missingName = field.label.length === 0;

  useEffect(() => {
    bump();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <div
      id={field.id}
      className={cx(styles.fieldContainer, {
        [styles.collapsed]: !expanded,
      })}
    >
      <div className={styles.fieldHeader}>
        {expanded ? (
          <>
            <AttachmentIcon label="attachments" />
            <TextInput
              isInvalid={missingName}
              placeholder="Field name"
              value={field.label}
              onChange={(e) => {
                onChange({
                  ...field,
                  label: e.currentTarget.value,
                });
              }}
            />
          </>
        ) : (
          <FormAttachmentsField field={field} isPreview />
        )}

        {!expanded && (
          <div className={styles.editActions}>
            <Button
              appearance="subtle"
              spacing="compact"
              isDisabled={missingName}
              onClick={() => setExpanded(!expanded)}
              iconBefore={<EditIcon size="small" label="edit" />}
            />

            <Button
              appearance="subtle"
              spacing="compact"
              iconBefore={<EditorCloseIcon label="remove" />}
              onClick={(e) => {
                e.stopPropagation();
                trelloIframe.popup({
                  mouseEvent: e,
                  title: 'Remove field',
                  type: 'confirm',
                  message:
                    'Are you sure you want to remove this field? There is no undo.',
                  confirmText: 'Remove',
                  confirmStyle: 'danger',
                  onConfirm: (t: any) => {
                    onRemove();
                    t.closePopup();
                  },
                });
              }}
            />
          </div>
        )}
      </div>

      {expanded && (
        <>
          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Description</div>
            <Textarea
              placeholder="Description"
              value={field.description}
              onChange={(e) => {
                onChange({
                  ...field,
                  description: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <Checkbox
              label="This field is required"
              isChecked={field.required}
              onChange={(e) => {
                onChange({
                  ...field,
                  required: e.currentTarget.checked,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <Checkbox
              label="Allow multiple uploads"
              isChecked={field.allowMultiple}
              onChange={(e) => {
                onChange({
                  ...field,
                  allowMultiple: e.currentTarget.checked,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <ConditionalSelection
              field={field}
              onChange={(conditional) => {
                onChange({
                  ...field,
                  conditional,
                });
              }}
            />
          </div>
        </>
      )}
      <div className={styles.fieldIsLinkedToText}>
        This field is linked to the card {field.targetField}.
      </div>

      {expanded && (
        <div className={styles.expandEditActions}>
          <Button
            appearance="primary"
            isDisabled={missingName}
            onClick={() => setExpanded(false)}
          >
            Done
          </Button>

          <Button
            appearance="subtle"
            onClick={(e) => {
              e.stopPropagation();
              trelloIframe.popup({
                mouseEvent: e,
                title: 'Remove field',
                type: 'confirm',
                message:
                  'Are you sure you want to remove this field? There is no undo.',
                confirmText: 'Remove',
                confirmStyle: 'danger',
                onConfirm: (t: any) => {
                  onRemove();
                  t.closePopup();
                },
              });
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

type FormAttachmentsFieldProps = {
  field: AttachmentsFieldType;
  isPreview?: boolean;
  fieldProps?: any;
};

export const FormAttachmentsField: React.FC<FormAttachmentsFieldProps> = ({
  field,
  isPreview,
  fieldProps,
}) => {
  const files = fieldProps?.value || [];

  return (
    <div className={styles.formFieldContainer}>
      <div className={styles.label}>{field.label}</div>
      {field.description && (
        <div className={styles.description}>{field.description}</div>
      )}
      <FileUploader
        maxSize={200}
        multiple={field.allowMultiple}
        file={!field.allowMultiple ? files[0] : undefined}
        files={field.allowMultiple ? files : undefined}
        handleChange={(fileOrFileList: File | FileList) => {
          if (field.allowMultiple) {
            fieldProps?.onChange([
              ...Array.from(files),
              ...Array.from(fileOrFileList as FileList),
            ]);
          } else {
            fieldProps?.onChange([fileOrFileList]);
          }
        }}
        name="file"
      >
        <div className={styles.uploadBox}>
          <div className={styles.uploadText}>
            Drag and drop files here or click to upload. Max size 200MB.
          </div>
          <div>
            {files.map((file: File) => (
              <div key={file.name}>{file.name}</div>
            ))}
          </div>
        </div>
      </FileUploader>
    </div>
  );
};
