import { TrelloPowerUpContext } from '@powerupsclub/trello';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  DropdownField as DropdownFieldType,
  TextField as TextFieldType,
  NumberField as NumberFieldType,
  DateField as DateFieldType,
  CheckboxField as CheckboxFieldType,
} from '../../api/types';
import { CheckboxField } from './CheckboxField';
import { DateField } from './DateField';
import { DropdownField } from './DropdownField';
import { NumberField } from './NumberField';
import { TextField } from './TextField';

type CustomDropdownFieldProps = {
  field: DropdownFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: DropdownFieldType) => void;
  onRemove: () => void;
};

export const CustomDropdownField: React.FC<CustomDropdownFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  const { trelloIframe } = useContext(TrelloPowerUpContext);
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    async function loadCustomFields() {
      const _board = await trelloIframe.board('customFields');
      setCustomFields(_board.customFields);
    }
    loadCustomFields();
  }, [trelloIframe]);

  const customField = useMemo(() => {
    const thisCustomField: any = customFields.find(
      (customField: any) => customField.id === field.id
    );

    let options = [];

    if (thisCustomField) {
      options = thisCustomField.options.map((option: any) => ({
        label: option.value.text,
        value: option.id,
      }));
    }

    return {
      ...field,
      options,
    };
  }, [field, customFields]);

  return (
    <DropdownField
      field={customField}
      defaultExpanded={defaultExpanded}
      onChange={onChange}
      onRemove={onRemove}
    />
  );
};

type CustomTextFieldProps = {
  field: TextFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: TextFieldType) => void;
  onRemove: () => void;
};

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  return (
    <TextField
      field={field}
      onChange={onChange}
      onRemove={onRemove}
      defaultExpanded={defaultExpanded}
    />
  );
};

type CustomNumberFieldProps = {
  field: NumberFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: NumberFieldType) => void;
  onRemove: () => void;
};

export const CustomNumberField: React.FC<CustomNumberFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  return (
    <NumberField
      field={field}
      onChange={onChange}
      onRemove={onRemove}
      defaultExpanded={defaultExpanded}
    />
  );
};

type CustomDateFieldProps = {
  field: DateFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: DateFieldType) => void;
  onRemove: () => void;
};

export const CustomDateField: React.FC<CustomDateFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  return (
    <DateField
      field={field}
      onChange={onChange}
      onRemove={onRemove}
      defaultExpanded={defaultExpanded}
    />
  );
};

type CustomCheckboxFieldProps = {
  field: CheckboxFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: CheckboxFieldType) => void;
  onRemove: () => void;
};

export const CustomCheckboxField: React.FC<CustomCheckboxFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  return (
    <CheckboxField
      field={field}
      onChange={onChange}
      onRemove={onRemove}
      defaultExpanded={defaultExpanded}
    />
  );
};
