import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import { ExternalForm } from './components/ExternalForm';

import '@atlaskit/css-reset';
import './index.css';
import { setGlobalTheme } from '@atlaskit/tokens';

setGlobalTheme({
  colorMode: 'auto',
  light: 'light',
  dark: 'dark',
  spacing: 'spacing',
});

const root = document.getElementById('root');

if (root?.dataset?.isexternalformpage === 'true') {
  ReactDOM.render(
    <HelmetProvider>
      <ExternalForm />
    </HelmetProvider>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}
