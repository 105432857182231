import styles from './FormsModal.module.scss';
import Button from '@atlaskit/button';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TrelloPowerUpContext } from '@powerupsclub/trello';
import { PaymentsContext } from '@powerupsclub/payments';
import Spinner from '@atlaskit/spinner';
import DynamicTable from '@atlaskit/dynamic-table';
import OpenIcon from '@atlaskit/icon/glyph/open';
import AddIcon from '@atlaskit/icon/glyph/add';
import ShareIcon from '@atlaskit/icon/glyph/share';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TextInput from '@atlaskit/textfield';
import MediaServicesDocumentIcon from '@atlaskit/icon/glyph/media-services/document';
import Avatar from '@atlaskit/avatar';
import slugify from 'slugify';
import { Form } from '../../api/types';
import { getForms } from '../../api/forms';
import { Redirect } from 'react-router-dom';
import { formsUrl } from '../../api/externalForms';

export const FormsPage: React.FC = () => {
  const {
    trelloIframe,
    isAuthorized,
    isLoading: isTrelloLoading,
  } = useContext(TrelloPowerUpContext);
  const {
    isLoading: isPaymentsLoading,
    subscription,
    trialEndDate,
  } = useContext(PaymentsContext);
  const [isLoadingForms, setIsLoadingForms] = useState(true);
  const [forms, setForms] = useState<Form[]>([]);
  const [boardData, setBoardData] = useState<any>();
  const [listsData, setListsData] = useState<any>();

  const [filterQuery, setFilterQuery] = useState<string>('');

  const isLoading = isLoadingForms || isTrelloLoading || isPaymentsLoading;

  const trialHasEnded = trialEndDate ? new Date() > trialEndDate : false;
  const notSubscribed = subscription?.status !== 'active';
  const showPayments = trialHasEnded && notSubscribed;

  useEffect(() => {
    async function loadForms() {
      if (!isAuthorized) {
        return;
      }

      const [_forms, _boardData, _listsData] = await Promise.all([
        getForms(trelloIframe),
        trelloIframe.board('all'),
        trelloIframe.lists('all'),
      ]);

      setIsLoadingForms(false);
      setForms(_forms);
      setBoardData(_boardData);
      setListsData(_listsData);
    }
    loadForms();
  }, [isAuthorized, trelloIframe]);

  const head = useMemo(
    () => ({
      cells: [
        {
          key: 'name',
          content: 'Name',
          isSortable: true,
        },
        {
          key: 'idList',
          content: 'Target List',
          isSortable: true,
        },
        {
          key: 'createdBy',
          content: 'Creator',
          isSortable: true,
        },
        {
          key: 'actions',
          content: '',
        },
      ],
    }),
    []
  );

  const rows = useMemo(() => {
    if (isLoading || !boardData || !listsData) {
      return [];
    }

    return forms
      .filter((form) => {
        const name = form.name.toLowerCase();
        const query = filterQuery.toLowerCase();
        return name.includes(query);
      })
      .map((form) => {
        const list = listsData.find((list: any) => list.id === form.idList);
        const member = boardData.members.find(
          (member: any) => member.id === form.idCreator
        );

        return {
          key: form.id,
          cells: [
            {
              key: `name:${form.name}:${form.id}`,
              content: (
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  {form.logo ? (
                    <img
                      src={form.logo}
                      style={{
                        width: '12px',
                        height: '12px',
                        marginLeft: '2px',
                      }}
                      alt="logo"
                    />
                  ) : (
                    <MediaServicesDocumentIcon size="small" label="form" />
                  )}
                  <span>{form.name}</span>
                </div>
              ),
            },
            {
              key: `idList:${list?.name}:${form.idList}:${form.id}`,
              content: list ? list.name : form.idList,
            },
            {
              key: `createdBy:${member?.fullName || member?.displayName}:${
                form.id
              }`,
              content: (
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  {member ? (
                    <>
                      <Avatar
                        size="xsmall"
                        src={member.avatar}
                        name={member.fullName}
                      />
                      <span>{member.fullName || member.displayName}</span>
                    </>
                  ) : (
                    <>
                      <Avatar size="xsmall" />
                      <span>Not on this board.</span>
                    </>
                  )}
                </div>
              ),
            },
            {
              key: `actions:${form.id}`,
              content: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Button
                    iconBefore={<EditIcon size="small" label="edit" />}
                    spacing="compact"
                    appearance="subtle"
                    onClick={() =>
                      trelloIframe.modal({
                        title: 'Forms',
                        url: `./form?formId=${form.id}`,
                        fullscreen: true,
                      })
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    iconBefore={<OpenIcon size="small" label="open" />}
                    spacing="compact"
                    appearance="subtle"
                    onClick={() => {
                      window.open(
                        `${formsUrl}/${form.id}/${slugify(
                          form.name.substring(0, 36),
                          {
                            lower: true,
                          }
                        )}`,
                        '_blank'
                      );
                    }}
                  ></Button>
                  <Button
                    iconBefore={<ShareIcon size="small" label="share" />}
                    spacing="compact"
                    appearance="subtle"
                    onClick={(e) => {
                      trelloIframe.popup({
                        mouseEvent: e,
                        title: 'Share',
                        url: `./share?formId=${form.id}&formName=${form.name}`,
                        height: 240,
                      });
                    }}
                  />
                </div>
              ),
            },
          ],
        };
      });
  }, [isLoading, boardData, listsData, forms, filterQuery, trelloIframe]);

  if (!(isTrelloLoading || isPaymentsLoading) && !isAuthorized) {
    return (
      <Redirect to={{ pathname: './authorize', state: { from: './forms' } }} />
    );
  }

  if (isLoading) {
    return (
      <div className={styles.loadingSpinnerContainer}>
        <Spinner size="large" />
      </div>
    );
  }

  if (showPayments) {
    return (
      <Redirect to={{ pathname: './payment', state: { from: './forms' } }} />
    );
  }

  return (
    <div className={styles.formsPage}>
      <div className={styles.header}>
        <TextInput
          placeholder="Search for a form..."
          value={filterQuery}
          onChange={(e) => setFilterQuery(e.currentTarget.value)}
        />
        <Button
          iconBefore={<AddIcon size="small" label="add" />}
          onClick={() =>
            trelloIframe.modal({
              title: 'Forms',
              url: './form?new=true',
              fullscreen: true,
            })
          }
        >
          Create a new form
        </Button>
      </div>
      <DynamicTable head={head} rows={rows} />
    </div>
  );
};
