import styles from './ColorSelector.module.scss';

import cx from 'classnames';
import Button from '@atlaskit/button';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import CrossIcon from '@atlaskit/icon/glyph/cross';

export const COLORS = [
  '#7BC86C',
  '#F5DD29',
  '#FFAF3F',
  '#EF7564',
  '#CD8DE5',
  '#5BA4CF',
  '#29CCE5',
  '#6DECA9',
  '#FF8ED4',
  '#172B4D',
];

type Props = {
  selectedColor?: string | null;
  setSelectedColor: (color?: string | null) => void;
};

export const ColorSelector: React.FC<Props> = ({
  selectedColor,
  setSelectedColor,
}) => {
  const [showColorPicker, setColorPicker] = useState(false);

  return (
    <div>
      <div className={styles.colorGrid}>
        {COLORS.map((color) => {
          return (
            <button
              key={color}
              className={cx(styles.colorButton, {
                [styles.selected]: selectedColor === color,
              })}
              style={{ backgroundColor: color }}
              onClick={() => {
                if (selectedColor === color) {
                  setSelectedColor(null);
                } else {
                  setSelectedColor(color);
                }
              }}
            />
          );
        })}
      </div>

      <div style={{ display: 'flex', gap: '4px' }}>
        <Button
          shouldFitContainer
          spacing="compact"
          onClick={() => setColorPicker(true)}
          className={cx({
            [styles.customColorSelected]: !COLORS.includes(
              selectedColor as string
            ),
          })}
        >
          Custom Color
        </Button>
        {selectedColor && (
          <Button
            onClick={() => setSelectedColor(null)}
            spacing="compact"
            iconBefore={<CrossIcon size="small" label="remove color" />}
          />
        )}
      </div>
      {showColorPicker && (
        <div style={{ position: 'absolute', zIndex: 1000, bottom: '0' }}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={() => setColorPicker(false)}
          />
          <ChromePicker
            color={selectedColor || '#fff'}
            onChange={(c) => setSelectedColor(c.hex)}
            disableAlpha
          />
        </div>
      )}
    </div>
  );
};
