import styles from './Fields.module.scss';
import Button from '@atlaskit/button';
import ListIcon from '@atlaskit/icon/glyph/list';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TextInput from '@atlaskit/textfield';
import Textarea from '@atlaskit/textarea';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Checkbox from '@atlaskit/checkbox';
import { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { DropdownField as DropdownFieldType } from '../../api/types';
import { TrelloPowerUpContext } from '@powerupsclub/trello';
import Select from '@atlaskit/select';
import { FormPageContext } from '../FormsModal/FormPage';
import { ConditionalSelection } from './ConditionalSelection';

type DropdownFieldProps = {
  field: DropdownFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: DropdownFieldType) => void;
  onRemove: () => void;
};

export type Member = {
  id: string;
  fullName: string;
  username: string;
  avatarUrl: string;
};

export const DropdownField: React.FC<DropdownFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  const { trelloIframe } = useContext(TrelloPowerUpContext);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { bump } = useContext(FormPageContext);

  useEffect(() => {
    bump();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const missingName = field.label.length === 0;

  return (
    <div
      id={field.id}
      className={cx(styles.fieldContainer, {
        [styles.collapsed]: !expanded,
      })}
    >
      <div className={styles.fieldHeader}>
        {expanded ? (
          <>
            <ListIcon label="dropdown" />
            <TextInput
              isInvalid={missingName}
              placeholder="Field name"
              value={field.label}
              onChange={(e) => {
                onChange({
                  ...field,
                  label: e.currentTarget.value,
                });
              }}
            />
          </>
        ) : (
          <FormDropdownField field={field} isPreview />
        )}

        {!expanded && (
          <div className={styles.editActions}>
            <Button
              appearance="subtle"
              spacing="compact"
              isDisabled={missingName}
              onClick={() => setExpanded(!expanded)}
              iconBefore={<EditIcon size="small" label="edit" />}
            />

            <Button
              appearance="subtle"
              spacing="compact"
              iconBefore={<EditorCloseIcon label="remove" />}
              onClick={(e) => {
                e.stopPropagation();
                trelloIframe.popup({
                  mouseEvent: e,
                  title: 'Remove field',
                  type: 'confirm',
                  message:
                    'Are you sure you want to remove this field? There is no undo.',
                  confirmText: 'Remove',
                  confirmStyle: 'danger',
                  onConfirm: (t: any) => {
                    onRemove();
                    t.closePopup();
                  },
                });
              }}
            />
          </div>
        )}
      </div>

      {expanded && (
        <>
          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Description</div>
            <Textarea
              placeholder="Description"
              value={field.description}
              onChange={(e) => {
                onChange({
                  ...field,
                  description: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Placeholder</div>
            <TextInput
              placeholder="Placeholder"
              value={field.placeholder}
              onChange={(e) => {
                onChange({
                  ...field,
                  placeholder: e.currentTarget.value,
                });
              }}
            />
          </div>

          {!field.customField && (
            <div className={styles.fieldSection}>
              <div className={styles.editLabel}>Options</div>
              <div className={styles.options}>
                {field.options.map((option, index) => (
                  <div className={styles.option} key={index}>
                    <TextInput
                      placeholder="Option"
                      value={option.label}
                      onChange={(e) => {
                        const newOptions = [...field.options];
                        newOptions[index] = {
                          label: e.currentTarget.value,
                          value: e.currentTarget.value,
                        };
                        onChange({
                          ...field,
                          options: newOptions,
                        });
                      }}
                    />
                    <Button
                      appearance="subtle"
                      iconBefore={<EditorCloseIcon label="remove" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        const newOptions = [...field.options];
                        newOptions.splice(index, 1);
                        onChange({
                          ...field,
                          options: newOptions,
                        });
                      }}
                    />
                  </div>
                ))}
                <Button
                  onClick={() => {
                    onChange({
                      ...field,
                      options: [
                        ...field.options,
                        {
                          label: '',
                          value: '',
                        },
                      ],
                    });
                  }}
                >
                  Add option
                </Button>
              </div>
            </div>
          )}

          <div className={styles.fieldSection}>
            <Checkbox
              label="This field is required"
              isChecked={field.required}
              onChange={(e) => {
                onChange({
                  ...field,
                  required: e.currentTarget.checked,
                });
              }}
            />
          </div>

          {!field.customField && (
            <div className={styles.fieldSection}>
              <Checkbox
                label="Allow user to select multiple options"
                isChecked={field.multiselect}
                onChange={(e) => {
                  onChange({
                    ...field,
                    multiselect: e.currentTarget.checked,
                  });
                }}
              />
            </div>
          )}

          <div className={styles.fieldSection}>
            <ConditionalSelection
              field={field}
              onChange={(conditional) => {
                onChange({
                  ...field,
                  conditional,
                });
              }}
            />
          </div>
        </>
      )}
      <div className={styles.fieldIsLinkedToText}>
        This field is linked to the card {field.targetField}.
      </div>

      {expanded && (
        <div className={styles.expandEditActions}>
          <Button
            appearance="primary"
            isDisabled={missingName}
            onClick={() => setExpanded(false)}
          >
            Done
          </Button>

          <Button
            appearance="subtle"
            onClick={(e) => {
              e.stopPropagation();
              trelloIframe.popup({
                mouseEvent: e,
                title: 'Remove field',
                type: 'confirm',
                message:
                  'Are you sure you want to remove this field? There is no undo.',
                confirmText: 'Remove',
                confirmStyle: 'danger',
                onConfirm: (t: any) => {
                  onRemove();
                  t.closePopup();
                },
              });
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

type FormDropdownFieldProps = {
  field: DropdownFieldType;
  isPreview?: boolean;
  fieldProps?: any;
};

export const FormDropdownField: React.FC<FormDropdownFieldProps> = ({
  field,
  isPreview,
  fieldProps,
}) => {
  return (
    <div className={styles.formFieldContainer}>
      <div className={cx(styles.label, { [styles.isPreview]: isPreview })}>
        {field.label}
      </div>
      {field.description && (
        <div className={styles.description}>{field.description}</div>
      )}

      <Select
        options={field.options}
        isMulti={field.multiselect}
        placeholder={field.placeholder}
        {...(fieldProps ? fieldProps : {})}
      />
    </div>
  );
};
