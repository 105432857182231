import cx from 'classnames';
import { TextField } from '../Fields/TextField';
import { DateField } from '../Fields/DateField';
import styles from './FormsModal.module.scss';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Select from '@atlaskit/select';
import TextInput from '@atlaskit/textfield';
import Textarea from '@atlaskit/textarea';
import MoreIcon from '@atlaskit/icon/glyph/more';
import ShareIcon from '@atlaskit/icon/glyph/share';
import OpenIcon from '@atlaskit/icon/glyph/open';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import EditorTextStyleIcon from '@atlaskit/icon/glyph/editor/text-style';
import LabelIcon from '@atlaskit/icon/glyph/label';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment';
import EditorBackgroundColorIcon from '@atlaskit/icon/glyph/editor/background-color';
import ImageIcon from '@atlaskit/icon/glyph/image';
import Icon from '@atlaskit/icon';
import ListIcon from '@atlaskit/icon/glyph/list';
import TaskIcon from '@atlaskit/icon/glyph/task';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TrelloPowerUpContext } from '@powerupsclub/trello';
import { EmptyCustomFieldToken, FieldToken } from '../FieldToken';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  DateField as DateFieldType,
  LabelsField as LabelsFieldType,
  MembersField as MembersFieldType,
  DropdownField as DropdownFieldType,
  CheckboxField as CheckboxFieldType,
  Field,
} from '../../api/types';
import { NumberField } from '../Fields/NumberField';
import { LabelsField } from '../Fields/LabelsField';
import { AttachmentsField } from '../Fields/AttachmentsField';
import { DropdownField } from '../Fields/DropdownField';
import { Member, MembersField } from '../Fields/MembersField';
import {
  CustomCheckboxField,
  CustomDateField,
  CustomDropdownField,
  CustomNumberField,
  CustomTextField,
} from '../Fields/CustomFields';
import { CheckboxField } from '../Fields/CheckboxField';
import {
  createForm,
  deleteForm,
  getFormById,
  updateForm,
} from '../../api/forms';
import { useLocation } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { v4 as uuid } from 'uuid';
import Toggle from '@atlaskit/toggle';
import { formsUrl } from '../../api/externalForms';
import Tooltip from '@atlaskit/tooltip';
import Xarrow from 'react-xarrows';
import { Label } from '../LabelSelect/LabelSelect';
import { ColorSelector } from '../ColorSelector';
import { UnsplashSelector } from '../UnsplashSelector';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import slugify from 'slugify';

const cardFields = [
  {
    key: 'name',
    label: 'Name',
    icon: <EditorTextStyleIcon size="small" label="name" />,
  },
  {
    key: 'startDate',
    label: 'Start Date',
    icon: <CalendarIcon size="small" label="start date" />,
  },
  {
    key: 'dueDate',
    label: 'Due Date',
    icon: <CalendarIcon size="small" label="due date" />,
  },
  {
    key: 'labels',
    label: 'Labels',
    icon: <LabelIcon size="small" label="labels" />,
  },
  {
    key: 'members',
    label: 'Members',
    icon: <PeopleIcon size="small" label="members" />,
  },
  {
    key: 'attachments',
    label: 'Attachments',
    icon: <AttachmentIcon size="small" label="attachments" />,
  },
];

const descriptionFields = [
  {
    key: 'description:text',
    label: 'Text',
    icon: <EditorTextStyleIcon size="small" label="text" />,
  },
  {
    key: 'description:number',
    label: 'Number',
    icon: (
      <Icon
        label="numbers"
        glyph={() => (
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M8.114 2.094a.75.75 0 01.386.656V9h1.252a.75.75 0 110 1.5H5.75a.75.75 0 010-1.5H7V4.103l-.853.533a.75.75 0 01-.795-1.272l2-1.25a.75.75 0 01.762-.02zm4.889 5.66a.75.75 0 01.75-.75h5.232a.75.75 0 01.53 1.28l-2.776 2.777c.55.097 1.057.253 1.492.483.905.477 1.504 1.284 1.504 2.418 0 .966-.471 1.75-1.172 2.27-.687.511-1.587.77-2.521.77-1.367 0-2.274-.528-2.667-.756a.75.75 0 01.755-1.297c.331.193.953.553 1.912.553.673 0 1.243-.188 1.627-.473.37-.275.566-.635.566-1.067 0-.5-.219-.836-.703-1.091-.538-.284-1.375-.443-2.471-.443a.75.75 0 01-.53-1.28l2.643-2.644h-3.421a.75.75 0 01-.75-.75zM7.88 15.215a1.4 1.4 0 00-1.446.83.75.75 0 01-1.37-.61 2.9 2.9 0 012.986-1.71 2.565 2.565 0 011.557.743c.434.446.685 1.058.685 1.778 0 1.641-1.254 2.437-2.12 2.986-.538.341-1.18.694-1.495 1.273H9.75a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75c0-1.799 1.337-2.63 2.243-3.21 1.032-.659 1.55-1.031 1.55-1.8 0-.355-.116-.584-.26-.732a1.068 1.068 0 00-.652-.298z"
            />
          </svg>
        )}
      />
    ),
  },
  {
    key: 'description:dropdown',
    label: 'Dropdown',
    icon: <ListIcon size="small" label="dropdown" />,
  },
];

function getIconForCustomField(cf: any) {
  switch (cf.type) {
    case 'list':
      return <ListIcon size="small" label="dropdown" />;
    case 'number':
      return (
        <Icon
          label="numbers"
          glyph={() => (
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8.114 2.094a.75.75 0 01.386.656V9h1.252a.75.75 0 110 1.5H5.75a.75.75 0 010-1.5H7V4.103l-.853.533a.75.75 0 01-.795-1.272l2-1.25a.75.75 0 01.762-.02zm4.889 5.66a.75.75 0 01.75-.75h5.232a.75.75 0 01.53 1.28l-2.776 2.777c.55.097 1.057.253 1.492.483.905.477 1.504 1.284 1.504 2.418 0 .966-.471 1.75-1.172 2.27-.687.511-1.587.77-2.521.77-1.367 0-2.274-.528-2.667-.756a.75.75 0 01.755-1.297c.331.193.953.553 1.912.553.673 0 1.243-.188 1.627-.473.37-.275.566-.635.566-1.067 0-.5-.219-.836-.703-1.091-.538-.284-1.375-.443-2.471-.443a.75.75 0 01-.53-1.28l2.643-2.644h-3.421a.75.75 0 01-.75-.75zM7.88 15.215a1.4 1.4 0 00-1.446.83.75.75 0 01-1.37-.61 2.9 2.9 0 012.986-1.71 2.565 2.565 0 011.557.743c.434.446.685 1.058.685 1.778 0 1.641-1.254 2.437-2.12 2.986-.538.341-1.18.694-1.495 1.273H9.75a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75c0-1.799 1.337-2.63 2.243-3.21 1.032-.659 1.55-1.031 1.55-1.8 0-.355-.116-.584-.26-.732a1.068 1.068 0 00-.652-.298z"
              />
            </svg>
          )}
        />
      );
    case 'date':
      return <CalendarIcon size="small" label="date" />;
    case 'checkbox':
      return <TaskIcon size="small" label="checkbox" />;
    default:
      return <EditorTextStyleIcon size="small" label="text" />;
  }
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const lineColors = [
  '#FF5630',
  '#FF8B00',
  '#FFC400',
  '#36B37E',
  '#00875A',
  '#00B8D9',
  '#0065FF',
  '#6554C0',
  '#FFAB00',
  '#FF5630',
  '#FF8B00',
  '#FFC400',
  '#36B37E',
];

export const FormPageContext = createContext<{
  formId: string | null;
  allFields: Field[];
  bump: () => void;
  members: Member[];
  labels: Label[];
}>({
  formId: null,
  allFields: [],
  bump: () => {},
  members: [],
  labels: [],
});

export const FormPage: React.FC = () => {
  const { trelloIframe, isLoading: isTrelloLoading } =
    useContext(TrelloPowerUpContext);

  const [customFields, setCustomFields] = useState<any[]>([]);
  const [lists, setLists] = useState<any[]>([]);

  const [formName, setFormName] = useState('');
  const [targetList, setTargetList] = useState<string | undefined>();
  const [fields, setFields] = useState<Field[]>([]);
  const [introductionMessage, setIntroductionMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('');
  const [addToTopOfList, setAddToTopOfList] = useState(false);
  const [showViewInTrelloButton, setShowViewInTrelloButton] = useState(false);
  const [hideCloseButton, setHideCloseButton] = useState(false);
  const [allowMultipleSubmissions, setAllowMultipleSubmissions] =
    useState(false);
  const [backgroundColor, setBackgroundColor] = useState<
    string | undefined | null
  >(null);
  const [unsplashBackground, setUnsplashBackground] = useState<string | null>(
    null
  );

  const [logo, setLogo] = useState<string | null>(null);

  const [newLogoFile, setNewLogoFile] = useState<File | null>(null);

  const [isLoadingForm, setIsLoadingForm] = useState(true);

  const [members, setMembers] = useState<Member[]>([]);
  const [labels, setLabels] = useState<Label[]>([]);

  const [isDragging, setIsDragging] = useState(false);
  const [lastBump, setLastBump] = useState(0);

  const [isSaving, setIsSaving] = useState(false);
  const [backgroundType, setBackgroundType] = useState<'color' | 'unsplash'>(
    'color'
  );

  const query = useQuery();
  const [formId, setFormId] = useState(query.get('formId'));

  const isLoading = isTrelloLoading || isLoadingForm;

  const isNewForm = !formId;

  const listOptions = useMemo(() => {
    return lists.map((list) => ({
      label: list.name,
      value: list.id,
    }));
  }, [lists]);

  const customFieldTokens = useMemo(() => {
    return customFields
      .filter((cf) => {
        const usedFields = fields.map((field) => field.id);

        return !usedFields.includes(cf.id);
      })
      .map((field) => ({
        key: field.id,
        label: field.name,
        icon: getIconForCustomField(field),
      }));
  }, [customFields, fields]);

  const validateConditionals = useCallback(
    (newFields: Field[]) => {
      const previousFieldIds: string[] = [];

      for (let i = 0; i < newFields.length; i++) {
        const field = newFields[i];

        if (field.conditional && field.conditional.idField) {
          if (!previousFieldIds.includes(field.conditional.idField)) {
            trelloIframe.alert({
              message: `Conditional fields must depend on fields that come before them. Removing dependency for ${newFields[i].label}.`,
            });

            newFields[i].conditional = undefined;

            return newFields;
          }

          const depedentField = newFields.find(
            (f) => f.id === field.conditional!.idField
          );

          if (
            depedentField?.type === 'dropdown' ||
            depedentField?.targetField === 'labels' ||
            depedentField?.type === 'members'
          ) {
            if (
              !(depedentField as any).multiselect &&
              ['contains', 'doesNotContain'].includes(
                field.conditional?.condition ?? ''
              )
            ) {
              newFields[i].conditional = undefined;
            }

            if (
              (depedentField as any).multiselect &&
              ['equals', 'doesNotEqual'].includes(
                field.conditional?.condition ?? ''
              )
            ) {
              newFields[i].conditional = undefined;
            }
          }
        }

        previousFieldIds.push(field.id);
      }

      return newFields;
    },
    [trelloIframe]
  );

  useEffect(() => {
    trelloIframe.updateModal({
      actions: [
        {
          icon: './back.png',
          alt: 'Back',
          position: 'left',
          callback: (t: any) => {
            t.modal({
              title: 'Forms',
              url: './forms',
              height: 600,
              actions: [
                {
                  icon: './gear.png',
                  alt: 'Settings',
                  position: 'left',
                  callback: async (y: any) => {
                    y.popup({
                      title: 'Forms - Settings',
                      items: [
                        {
                          text: 'Manage subscription...',
                          callback: async (x: any) => {
                            return x.modal({
                              title: 'Forms - Subscription',
                              url: './payment',
                              fullscreen: true,
                            });
                          },
                        },
                        {
                          text: 'Give us feedback...',
                          callback: (x: any) => {
                            x.popup({
                              title: 'Give us feedback',
                              url: './feedback',
                              height: 350,
                            });
                          },
                        },
                      ],
                    });
                  },
                },
              ],
            });
          },
        },
        {
          icon: './gear.png',
          alt: 'Settings',
          position: 'left',
          callback: async (y: any) => {
            y.popup({
              title: 'Forms - Settings',
              items: [
                {
                  text: 'Manage subscription...',
                  callback: async (x: any) => {
                    return x.modal({
                      title: 'Forms - Subscription',
                      url: './payment',
                      fullscreen: true,
                    });
                  },
                },
                {
                  text: 'Give us feedback...',
                  callback: (x: any) => {
                    x.popup({
                      title: 'Give us feedback',
                      url: './feedback',
                      height: 350,
                    });
                  },
                },
              ],
            });
          },
        },
      ],
    });

    async function load() {
      const [_board, _lists] = await Promise.all([
        trelloIframe.board('customFields', 'members', 'labels'),
        trelloIframe.lists('all'),
      ]);

      if (formId) {
        try {
          const _form = await getFormById(trelloIframe, formId);

          if (_form) {
            setFormName(_form.name);
            setTargetList(_form.idList);
            setFields(_form.fields);
            if (_form.introductionMessage) {
              setIntroductionMessage(_form.introductionMessage);
            }
            if (_form.confirmationMessage) {
              setConfirmationMessage(_form.confirmationMessage);
            }
            if (_form.submitButtonText) {
              setSubmitButtonText(_form.submitButtonText);
            }
            if (_form.addToTopOfList) {
              setAddToTopOfList(_form.addToTopOfList);
            }
            if (_form.showViewInTrelloButton) {
              setShowViewInTrelloButton(_form.showViewInTrelloButton);
            }
            if (_form.hideCloseButton) {
              setHideCloseButton(_form.hideCloseButton);
            }
            if (_form.allowMultipleSubmissions) {
              setAllowMultipleSubmissions(_form.allowMultipleSubmissions);
            }
            if (_form.backgroundColor) {
              setBackgroundColor(_form.backgroundColor);
              setBackgroundType('color');
            }
            if (_form.unsplashBackground) {
              setUnsplashBackground(_form.unsplashBackground);
              setBackgroundType('unsplash');
            }
            if (_form.logo) {
              setLogo(_form.logo);
            }
          }
        } catch (e) {
          trelloIframe.alert({
            message: 'Error loading form. Please try again later.',
          });

          trelloIframe.modal({
            title: 'Forms',
            url: './forms',
            height: 600,
          });
        }
      }

      setCustomFields(_board.customFields);
      setLists(_lists);
      setLabels(_board.labels);
      setMembers(_board.members);
      setIsLoadingForm(false);
    }

    load();
  }, [formId, trelloIframe]);

  useEffect(() => {
    if (lists.length) {
      if (!targetList) {
        setTargetList(lists[0].id);
      }
    }
  }, [lists, targetList]);

  const createFormHandler = useCallback(async () => {
    setIsSaving(true);

    try {
      const newForm = await createForm(
        trelloIframe,
        {
          name: formName,
          idList: targetList,
          fields,
          introductionMessage,
          confirmationMessage,
          submitButtonText,
          addToTopOfList,
          showViewInTrelloButton,
          hideCloseButton,
          allowMultipleSubmissions,
          backgroundColor,
          unsplashBackground,
        },
        newLogoFile
      );

      setFormId(newForm.id);

      trelloIframe.alert({
        message: 'Form has been created!',
      });
    } catch (e) {
      trelloIframe.alert({
        message: 'Failed to create form. Please try again later.',
      });
    }

    setIsSaving(false);
  }, [
    addToTopOfList,
    allowMultipleSubmissions,
    confirmationMessage,
    fields,
    formName,
    hideCloseButton,
    introductionMessage,
    showViewInTrelloButton,
    submitButtonText,
    targetList,
    trelloIframe,
    backgroundColor,
    unsplashBackground,
    newLogoFile,
  ]);

  const updateFormHandler = useCallback(async () => {
    if (!formId) {
      return;
    }

    setIsSaving(true);

    try {
      await updateForm(
        trelloIframe,
        {
          id: formId,
          name: formName,
          idList: targetList,
          fields,
          introductionMessage,
          confirmationMessage,
          submitButtonText,
          addToTopOfList,
          showViewInTrelloButton,
          hideCloseButton,
          allowMultipleSubmissions,
          backgroundColor,
          unsplashBackground,
          ...(!logo && !newLogoFile ? { logo: null } : {}),
        },
        newLogoFile
      );

      trelloIframe.alert({
        message: 'Form has been saved!',
      });
    } catch (e) {
      trelloIframe.alert({
        message: 'Failed to save form. Please try again later.',
      });
    }

    setIsSaving(false);
  }, [
    formId,
    trelloIframe,
    formName,
    targetList,
    fields,
    introductionMessage,
    confirmationMessage,
    submitButtonText,
    addToTopOfList,
    showViewInTrelloButton,
    hideCloseButton,
    allowMultipleSubmissions,
    backgroundColor,
    unsplashBackground,
    logo,
    newLogoFile,
  ]);

  const deleteFormHandler = useCallback(async () => {
    if (!formId) {
      return;
    }

    try {
      await deleteForm(trelloIframe, formId);

      trelloIframe.alert({
        message: 'Form has been deleted.',
      });

      trelloIframe.modal({
        title: 'Forms',
        url: './forms',
        height: 600,
      });
    } catch (e) {
      trelloIframe.alert({
        message: 'Failed to delete form. Please try again later.',
      });
    }
  }, [formId, trelloIframe]);

  const isInvalidForm =
    formName.length === 0 ||
    fields.length === 0 ||
    fields.some((field) => field.label.length === 0);

  const canSaveForm = !isInvalidForm;

  const avaiableCardFields = useMemo(() => {
    const usedFields = fields.map((field) => field.id);

    return cardFields.filter((field) => !usedFields.includes(field.key));
  }, [fields]);

  const bumpRender = () => {
    setLastBump(lastBump + 1);
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);
    if (result.destination?.droppableId !== 'form-fields') {
      return; // do nothing
    }

    const newFields = [...fields];

    if (result.source.droppableId === 'form-fields') {
      // reorder fields list
      const [removed] = newFields.splice(result.source.index, 1);
      newFields.splice(result.destination.index, 0, removed);

      setFields(validateConditionals(newFields));

      return;
    }

    if (result.source.droppableId === 'card-fields') {
      switch (result.draggableId) {
        case 'name':
          newFields.splice(result.destination.index, 0, {
            id: 'name',
            type: 'text',
            label: 'Name',
            targetField: 'name',
          });
          break;
        case 'startDate':
          newFields.splice(result.destination.index, 0, {
            id: 'startDate',
            type: 'date',
            label: 'Start date',
            targetField: 'startDate',
          });
          break;
        case 'dueDate':
          newFields.splice(result.destination.index, 0, {
            id: 'dueDate',
            type: 'date',
            label: 'Due date',
            targetField: 'dueDate',
          });
          break;
        case 'labels':
          newFields.splice(result.destination.index, 0, {
            id: 'labels',
            type: 'labels',
            label: 'Labels',
            targetField: 'labels',
            multiselect: false,
            style: 'dropdown',
          });
          break;
        case 'members':
          newFields.splice(result.destination.index, 0, {
            id: 'members',
            type: 'members',
            label: 'Member',
            targetField: 'members',
            multiselect: false,
            style: 'dropdown',
          });
          break;
        case 'attachments':
          newFields.splice(result.destination.index, 0, {
            id: 'attachments',
            type: 'attachments',
            label: 'Attachments',
            targetField: 'attachments',
          });
          break;
        default:
        // do nothing
      }
    }

    if (result.source.droppableId === 'description-fields') {
      switch (result.draggableId) {
        case 'description:text':
          newFields.splice(result.destination.index, 0, {
            id: `description:text:${uuid()}`,
            type: 'text',
            label: 'Text',
            targetField: 'description',
            multiline: false,
          });
          break;
        case 'description:number':
          newFields.splice(result.destination.index, 0, {
            id: `description:number:${uuid()}`,
            type: 'number',
            label: 'Number',
            targetField: 'description',
          });
          break;
        case 'description:dropdown':
          newFields.splice(result.destination.index, 0, {
            id: `description:dropdown:${uuid()}`,
            type: 'dropdown',
            label: 'Dropdown',
            targetField: 'description',
            options: [],
          });
          break;
        default:
        // do nothing
      }
    }

    if (result.source.droppableId === 'custom-fields') {
      const field = customFields.find((cf) => cf.id === result.draggableId);

      if (!field) {
        return;
      }

      let type = 'text';

      switch (field.type) {
        case 'list':
          type = 'dropdown';
          break;
        case 'number':
          type = 'number';
          break;
        case 'date':
          type = 'date';
          break;
        case 'checkbox':
          type = 'checkbox';
          break;
        default:
          type = 'text';
      }

      newFields.splice(result.destination.index, 0, {
        id: field.id,
        type,
        label: field.name,
        targetField: field.id,
        customField: true,
        options: [],
      });
    }

    setFields(validateConditionals(newFields));
  };

  if (isLoading) {
    return (
      <div className={styles.loadingSpinnerContainer}>
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <FormPageContext.Provider
      value={{
        formId,
        bump: bumpRender,
        allFields: fields,
        labels,
        members,
      }}
    >
      <div className={styles.formsModal}>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <div className={styles.relativeContainer}>
            <div className={styles.fieldsMenu}>
              {avaiableCardFields.length > 0 && (
                <h2 className={styles.label}>Card fields</h2>
              )}

              <Droppable droppableId="card-fields" isDropDisabled>
                {(provided) => (
                  <div
                    className={styles.fieldTokenList}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {avaiableCardFields.map((field, index) => (
                      <Draggable
                        key={field.key}
                        draggableId={field.key}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                transform: snapshot.isDragging
                                  ? provided.draggableProps.style?.transform
                                  : 'translate(0px, 0px)',
                              }}
                            >
                              <FieldToken
                                name={field.label}
                                icon={field.icon}
                              />
                            </div>
                            {snapshot.isDragging && (
                              <div className={styles.fieldTokenClone}></div>
                            )}
                          </>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>

              <h2 className={styles.label}>Description fields</h2>
              <Droppable droppableId="description-fields" isDropDisabled>
                {(provided) => (
                  <div
                    className={styles.fieldTokenList}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {descriptionFields.map((field, index) => (
                      <Draggable
                        key={field.key}
                        draggableId={field.key}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                transform: snapshot.isDragging
                                  ? provided.draggableProps.style?.transform
                                  : 'translate(0px, 0px)',
                              }}
                            >
                              <FieldToken
                                name={field.label}
                                icon={field.icon}
                              />
                            </div>
                            {snapshot.isDragging && (
                              <FieldToken
                                name={field.label}
                                icon={field.icon}
                              />
                            )}
                          </>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>

              <h2 className={styles.label}>Custom fields</h2>
              <div className={styles.fieldTokenList}>
                {customFields.length === 0 && <EmptyCustomFieldToken />}
              </div>
              <Droppable droppableId="custom-fields" isDropDisabled>
                {(provided) => (
                  <div
                    className={styles.fieldTokenList}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {customFieldTokens.map((field, index) => (
                      <Draggable
                        key={field.key}
                        draggableId={field.key}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                transform: snapshot.isDragging
                                  ? provided.draggableProps.style?.transform
                                  : 'translate(0px, 0px)',
                              }}
                            >
                              <FieldToken
                                name={field.label}
                                icon={field.icon}
                              />
                            </div>
                            {snapshot.isDragging && (
                              <div className={styles.fieldTokenClone}></div>
                            )}
                          </>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>
          </div>

          <div
            className={styles.centerDiv}
            style={{
              backgroundColor: backgroundColor ?? undefined,
              ...(unsplashBackground
                ? {
                    backgroundImage: `url(${unsplashBackground})`,
                    backgroundSize: 'cover',
                  }
                : {}),
            }}
          >
            <div className={styles.formContent}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <Tooltip
                  key={logo ? 'remove-logo' : 'add-logo'}
                  content={logo ? 'Remove logo' : 'Add a logo'}
                >
                  <div
                    style={{
                      backgroundImage: logo ? `url(${logo})` : undefined,
                    }}
                    className={cx({
                      [styles.logo]: !!logo,
                      [styles.addLogo]: !logo,
                    })}
                  >
                    {!logo && (
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/gif"
                        onChange={(e) => {
                          if (e.target.files?.length) {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              if (e.target?.result) {
                                setLogo(e.target.result as string);
                              }
                            };
                            reader.readAsDataURL(file);
                            setNewLogoFile(file);
                          }
                        }}
                      />
                    )}
                    {logo && (
                      <div
                        className={styles.removeLogoIcon}
                        onClick={() => {
                          setLogo(null);
                          setNewLogoFile(null);
                        }}
                      >
                        <CrossIcon label="remove logo" />
                      </div>
                    )}
                  </div>
                </Tooltip>
                <div style={{ width: '100%' }}>
                  <TextInput
                    placeholder="Form name"
                    value={formName}
                    onChange={(e) => setFormName(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div>
                <h2 className={styles.label}>Fields</h2>
                <Droppable droppableId="form-fields">
                  {(provided) => (
                    <div
                      className={cx(styles.fieldsList, {
                        [styles.empty]: fields.length === 0,
                      })}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {fields.map((field, index) => (
                        <Draggable
                          draggableId={field.id}
                          key={field.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={styles.draggableFieldWrapper}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {(function () {
                                const onChange = (field: Field) => {
                                  const newFields = [...fields];
                                  newFields[index] = field;
                                  setFields(validateConditionals(newFields));
                                };

                                const onRemove = () => {
                                  const newFields = [...fields];
                                  newFields.splice(index, 1);
                                  setFields(validateConditionals(newFields));
                                };

                                // TODO: determine this by diff-ing stored fields vs fields in state.
                                const isNew = false;

                                switch (field.type) {
                                  case 'text':
                                    if (field.customField) {
                                      return (
                                        <CustomTextField
                                          field={field}
                                          onChange={onChange}
                                          onRemove={onRemove}
                                        />
                                      );
                                    }

                                    return (
                                      <TextField
                                        field={field}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        defaultExpanded={isNew}
                                      />
                                    );
                                  case 'date':
                                    if (field.customField) {
                                      return (
                                        <CustomDateField
                                          field={field}
                                          onChange={onChange}
                                          onRemove={onRemove}
                                          defaultExpanded={isNew}
                                        />
                                      );
                                    }

                                    return (
                                      <DateField
                                        field={field as DateFieldType}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        defaultExpanded={isNew}
                                      />
                                    );
                                  case 'number':
                                    if (field.customField) {
                                      return (
                                        <CustomNumberField
                                          field={field}
                                          onChange={onChange}
                                          onRemove={onRemove}
                                          defaultExpanded={isNew}
                                        />
                                      );
                                    }

                                    return (
                                      <NumberField
                                        field={field}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        defaultExpanded={isNew}
                                      />
                                    );
                                  case 'labels':
                                    return (
                                      <LabelsField
                                        field={field as LabelsFieldType}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        defaultExpanded={isNew}
                                      />
                                    );
                                  case 'members':
                                    return (
                                      <MembersField
                                        field={field as MembersFieldType}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        defaultExpanded={isNew}
                                      />
                                    );
                                  case 'attachments':
                                    return (
                                      <AttachmentsField
                                        field={field}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        defaultExpanded={isNew}
                                      />
                                    );
                                  case 'dropdown':
                                    if (field.customField) {
                                      return (
                                        <CustomDropdownField
                                          field={field as DropdownFieldType}
                                          onChange={onChange}
                                          onRemove={onRemove}
                                          defaultExpanded={isNew}
                                        />
                                      );
                                    }
                                    return (
                                      <DropdownField
                                        field={field as DropdownFieldType}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        defaultExpanded={isNew}
                                      />
                                    );
                                  case 'checkbox':
                                    if (field.customField) {
                                      return (
                                        <CustomCheckboxField
                                          field={field as CheckboxFieldType}
                                          onChange={onChange}
                                          onRemove={onRemove}
                                          defaultExpanded={isNew}
                                        />
                                      );
                                    }

                                    return (
                                      <CheckboxField
                                        field={field as CheckboxFieldType}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        defaultExpanded={isNew}
                                      />
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {!isDragging &&
                        fields
                          .filter((field) => !!field.conditional)
                          .map((field, index) => {
                            const { conditional } = field;

                            if (!conditional) {
                              return null;
                            }

                            const { idField } = conditional;

                            const dependentField = fields.find(
                              (f) => f.id === idField
                            );

                            if (!dependentField) {
                              return null;
                            }

                            return (
                              <Tooltip
                                key={`${field.id}-${dependentField.id}-${lastBump}`}
                                content={`${field.label} depends on ${dependentField.label}`}
                                position="mouse"
                                mousePosition="left"
                              >
                                <Xarrow
                                  key={`${field.id}-${dependentField.id}-${lastBump}`}
                                  start={field.id}
                                  end={dependentField.id}
                                  startAnchor={{
                                    position: 'left',
                                    offset: {
                                      y: -24,
                                    },
                                  }}
                                  endAnchor={{
                                    position: 'left',
                                    offset: {
                                      y: 24,
                                    },
                                  }}
                                  showHead={false}
                                  path="grid"
                                  gridBreak="16"
                                  strokeWidth={4}
                                  dashness
                                  color={lineColors[index]}
                                />
                              </Tooltip>
                            );
                          })}

                      {provided.placeholder}
                      {fields.length === 0 && (
                        <div className={styles.emptyMessage}>
                          Drag and drop fields here to build your form.
                        </div>
                      )}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </div>

          <div className={styles.relativeContainer}>
            <div className={styles.formSettings}>
              <LoadingButton
                isLoading={isSaving}
                appearance="primary"
                shouldFitContainer
                isDisabled={!canSaveForm}
                onClick={() => {
                  if (formId) {
                    updateFormHandler();
                  } else {
                    createFormHandler();
                  }
                }}
              >
                {isNewForm ? 'Create form' : 'Save form'}
              </LoadingButton>

              <ButtonGroup>
                <Button
                  appearance="default"
                  iconBefore={<OpenIcon size="small" label="preview" />}
                  isDisabled={!formId}
                  onClick={() => {
                    window.open(
                      `${formsUrl}/${formId}/${slugify(
                        formName.substring(0, 36),
                        {
                          lower: true,
                        }
                      )}`,
                      '_blank'
                    );
                  }}
                >
                  Open
                </Button>
                <Button
                  appearance="default"
                  iconBefore={<ShareIcon size="small" label="share" />}
                  isDisabled={!formId}
                  onClick={(e) => {
                    trelloIframe.popup({
                      mouseEvent: e,
                      title: 'Share',
                      url: `./share?formId=${formId}&formName=${formName}`,
                      height: 240,
                    });
                  }}
                >
                  Share
                </Button>
                <Button
                  appearance="default"
                  iconBefore={<MoreIcon size="small" label="more actions" />}
                  isDisabled={!formId}
                  onClick={(e) => {
                    trelloIframe.popup({
                      mouseEvent: e,
                      title: 'Form actions',
                      items: [
                        {
                          text: 'Delete form...',
                          callback: (t: any) => {
                            t.popup({
                              title: 'Delete form',
                              type: 'confirm',
                              message:
                                'Are you sure you want to delete this form? There is no undo.',
                              confirmText: 'Delete form',
                              confirmStyle: 'danger',
                              onConfirm: deleteFormHandler,
                              cancelText: 'Cancel',
                            });
                          },
                        },
                      ],
                    });
                  }}
                />
              </ButtonGroup>

              <hr
                style={{
                  border: 'none',
                  borderTop: '1px solid #eaeaea',
                  margin: '1px 0 0',
                }}
              />

              <div className={styles.settingsSections}>
                <div>
                  <div className={styles.settingsSubsection}>
                    <h2 className={styles.label}>Target List</h2>
                    <Select
                      options={listOptions}
                      isDisabled={lists.length === 0}
                      value={listOptions.find((l) => l.value === targetList)}
                      onChange={(e: any) => setTargetList(e.value as string)}
                    />
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'flex-end',
                        marginTop: '8px',
                      }}
                    >
                      <label htmlFor="addToTopOfList" className={styles.label}>
                        Add to the top of the list
                      </label>
                      <Toggle
                        id="addToTopOfList"
                        isChecked={addToTopOfList}
                        onChange={(e) => setAddToTopOfList(e.target.checked)}
                      />
                    </div>
                  </div>

                  <div className={styles.settingsSubsection}>
                    <h2 className={styles.label}>Introduction message</h2>
                    <Textarea
                      placeholder="This appears before your form fields."
                      value={introductionMessage}
                      onChange={(e) =>
                        setIntroductionMessage(e.currentTarget.value)
                      }
                    />
                  </div>

                  <div className={styles.settingsSubsection}>
                    <h2 className={styles.label}>Submit button text</h2>
                    <TextInput
                      placeholder="Submit"
                      value={submitButtonText}
                      onChange={(e) =>
                        setSubmitButtonText(e.currentTarget.value)
                      }
                    />
                  </div>

                  <div className={styles.settingsSubsection}>
                    <h2 className={styles.label}>Confirmation message</h2>
                    <Textarea
                      placeholder="This appears after the user has submitted the form."
                      value={confirmationMessage}
                      onChange={(e) =>
                        setConfirmationMessage(e.currentTarget.value)
                      }
                    />
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'flex-end',
                        marginTop: '8px',
                      }}
                    >
                      <label
                        htmlFor="showViewInTrelloButton"
                        className={styles.label}
                      >
                        Show "View in Trello" button
                      </label>
                      <Toggle
                        id="showViewInTrelloButton"
                        isChecked={showViewInTrelloButton}
                        onChange={(e) =>
                          setShowViewInTrelloButton(e.target.checked)
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'flex-end',
                        marginTop: '8px',
                      }}
                    >
                      <label
                        htmlFor="allowMultipleSubmissions"
                        className={styles.label}
                      >
                        Allow multiple submissions
                      </label>
                      <Toggle
                        label="allowMultipleSubmissions"
                        isChecked={allowMultipleSubmissions}
                        onChange={(e) =>
                          setAllowMultipleSubmissions(e.target.checked)
                        }
                      />
                    </div>
                  </div>

                  <div className={styles.settingsSubsection}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <h2 className={styles.label}>Background</h2>

                      <div
                        style={{
                          display: 'flex',
                          gap: '4px',
                          marginBottom: '4px',
                        }}
                      >
                        <Button
                          spacing="compact"
                          isSelected={backgroundType === 'color'}
                          onClick={() => setBackgroundType('color')}
                          iconBefore={
                            <EditorBackgroundColorIcon
                              label="color backgrounds"
                              size="small"
                            />
                          }
                        />
                        <Button
                          spacing="compact"
                          isSelected={backgroundType === 'unsplash'}
                          onClick={() => setBackgroundType('unsplash')}
                          iconBefore={
                            <ImageIcon
                              label="unsplash backgrounds"
                              size="small"
                            />
                          }
                        />
                      </div>
                    </div>
                    {backgroundType === 'color' ? (
                      <ColorSelector
                        selectedColor={backgroundColor}
                        setSelectedColor={(color) => {
                          setUnsplashBackground(null);
                          setBackgroundColor(color);
                        }}
                      />
                    ) : (
                      <UnsplashSelector
                        selectedUnsplash={unsplashBackground}
                        setSelectedUnsplash={(unsplash) => {
                          setBackgroundColor(null);
                          setUnsplashBackground(unsplash);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DragDropContext>
      </div>
    </FormPageContext.Provider>
  );
};
