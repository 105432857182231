import { Field } from './types';

export type FieldWithValue = Field & { value?: any };

export function shouldAppear(
  field: FieldWithValue,
  dependencyField: FieldWithValue
): boolean {
  if (!field.conditional) {
    return true;
  }

  const { condition, value, idField } = field.conditional;

  if (idField !== dependencyField.id) {
    return true;
  }

  switch (condition) {
    case 'equals':
      return dependencyField.value === value;
    case 'doesNotEqual':
      return dependencyField.value !== value;
    case 'isEmpty':
      return !dependencyField.value;
    case 'isNotEmpty':
      return !!dependencyField.value;
    case 'isGreaterThan':
      if (!value) return false;
      return dependencyField.value > value;
    case 'isLessThan':
      if (!value) return false;
      return dependencyField.value < value;
    case 'isGreaterThanOrEqualTo':
      if (!value) return false;
      return dependencyField.value >= value;
    case 'isLessThanOrEqualTo':
      if (!value) return false;
      return dependencyField.value <= value;
    case 'isChecked':
      return dependencyField.value;
    case 'isNotChecked':
      return !dependencyField.value;
    case 'contains':
      if (!value) return false;
      return dependencyField.value?.includes(value) || false;
    case 'doesNotContain':
      if (!value) return false;
      return !dependencyField.value?.includes(value) || false;
    default:
      return true;
  }
}
