import styles from './UnsplashSelector.module.scss';

import Button, { ButtonGroup } from '@atlaskit/button';
import Textfield from '@atlaskit/textfield';
import { useEffect, useState } from 'react';
import cx from 'classnames';

async function searchUnsplash(query: string, page: number) {
  const response = await fetch(
    `https://trello.com/proxy/unsplash/search/photos?query=${query}&content_filter=high&per_page=${6}&page=${page}`
  );
  const data = await response.json();
  return data.results;
}

type Props = {
  selectedUnsplash: string | null;
  setSelectedUnsplash: (unsplash: string | null) => void;
};

export const UnsplashSelector: React.FC<Props> = ({
  selectedUnsplash,
  setSelectedUnsplash,
}) => {
  const [searchTerm, setSearchTerm] = useState('patterns');
  const [page, setPage] = useState(1);
  const [unsplashImages, setUnsplashImages] = useState<any[]>([]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      searchUnsplash(searchTerm, page).then((data) => {
        if (data?.length) {
          setUnsplashImages(data);
        }
      });
    }
  }, [page, searchTerm]);

  useEffect(() => {
    // resets page when search term changes
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    if (unsplashImages.length > 0 && !selectedUnsplash) {
      setSelectedUnsplash(unsplashImages[0].urls.regular);
    }
  }, [selectedUnsplash, setSelectedUnsplash, unsplashImages]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Textfield
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
        />
        <div className={styles.imageButtonGrid}>
          {unsplashImages.map((a) => (
            <div
              key={a.id}
              className={cx(styles.imageButton, {
                [styles.selected]: a.urls.regular === selectedUnsplash,
              })}
              style={{
                background: `url(${a.urls.regular})`,
                backgroundSize: 'cover',
              }}
              onClick={() => setSelectedUnsplash(a.urls.regular)}
            />
          ))}
        </div>
        <ButtonGroup>
          <Button
            spacing="compact"
            isDisabled={page === 1}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            Back
          </Button>
          <Button
            spacing="compact"
            isDisabled={unsplashImages.length < 6}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
