import { Checkbox } from '@atlaskit/checkbox';
import { Conditional, Field } from '../../api/types';
import Select from '@atlaskit/select';
import TextInput from '@atlaskit/textfield';
import { useContext, useMemo } from 'react';
import { FormPageContext } from '../FormsModal/FormPage';

type Props = {
  field: Field;
  onChange: (c?: Conditional) => void;
};

const baseOptions = [
  {
    label: 'Equals',
    value: 'equals',
  },
  {
    label: 'does not equal',
    value: 'doesNotEqual',
  },
];

const emptyOptions = [
  {
    label: 'is empty',
    value: 'isEmpty',
  },
  {
    label: 'is not empty',
    value: 'isNotEmpty',
  },
];

const numberOptions = [
  {
    label: 'is greater than',
    value: 'isGreaterThan',
  },
  {
    label: 'is less than',
    value: 'isLessThan',
  },
  {
    label: 'is greater than or equal to',
    value: 'isGreaterThanOrEqualTo',
  },
  {
    label: 'is less than or equal to',
    value: 'isLessThanOrEqualTo',
  },
];

const multiselectOptions = [
  {
    label: 'contains',
    value: 'contains',
  },
  {
    label: 'does not contain',
    value: 'doesNotContain',
  },
];

const checkboxOptions = [
  {
    label: 'is checked',
    value: 'isChecked',
  },
  {
    label: 'is not checked',
    value: 'isNotChecked',
  },
];

export const ConditionalSelection: React.FC<Props> = ({ field, onChange }) => {
  const { allFields, labels, members } = useContext(FormPageContext);

  const fieldOptions = useMemo(() => {
    const indexOfField = allFields.findIndex((f) => f.id === field.id);

    return allFields
      .filter((f, index) => f.id !== field.id && index < indexOfField)
      .map((f) => ({
        label: f.label,
        value: f.id,
      }));
  }, [allFields, field.id]);

  const conditionOptions = useMemo(() => {
    if (!field.conditional?.idField) {
      return [];
    }

    const dependentField = allFields.find(
      (f) => f.id === field.conditional?.idField
    );

    if (!dependentField) {
      return [];
    }

    if (
      dependentField.targetField === 'labels' ||
      dependentField.targetField === 'members'
    ) {
      if ((dependentField as any).multiselect) {
        return [...emptyOptions, ...multiselectOptions];
      }
    }

    if (dependentField.type === 'date') {
      return emptyOptions;
    }

    if (dependentField.type === 'checkbox') {
      return checkboxOptions;
    }

    const options = [...emptyOptions, ...baseOptions];

    if (dependentField.type === 'number') {
      options.push(...numberOptions);
    }

    return options;
  }, [allFields, field.conditional]);

  const valueOptions = useMemo(() => {
    if (!field.conditional?.idField) {
      return [];
    }

    const dependentField = allFields.find(
      (f) => f.id === field.conditional?.idField
    );

    if (!dependentField) {
      return [];
    }

    if (dependentField.type === 'dropdown') {
      if ((dependentField as any).options) {
        return (dependentField as any).options;
      }
    }

    if (dependentField.targetField === 'labels') {
      return labels.map((l) => ({
        label: l.name || l.color,
        value: l.id,
      }));
    }

    if (dependentField.targetField === 'members') {
      return members.map((m) => ({
        label: m.fullName || m.username,
        value: m.id,
      }));
    }

    return [];
  }, [allFields, field.conditional?.idField, labels, members]);

  if (!fieldOptions.length) {
    return null;
  }

  const hasConditional = !!field.conditional;
  const shouldShowValueInput =
    field.conditional?.condition &&
    ![...emptyOptions, ...checkboxOptions]
      .map((o) => o.value)
      .includes(field.conditional?.condition);

  return (
    <div>
      <Checkbox
        label="This field depends on another field"
        isChecked={hasConditional}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            onChange({
              idField: '',
            });
          } else {
            onChange(undefined);
          }
        }}
      />
      {hasConditional && (
        <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
          <div style={{ width: '100%' }}>
            <Select
              options={fieldOptions}
              menuPlacement="top"
              placeholder="Field"
              value={
                field.conditional?.idField
                  ? fieldOptions.find(
                      (f) => f.value === field.conditional?.idField
                    )
                  : null
              }
              onChange={(e: any) => {
                const newField = allFields.find((f) => f.id === e.value)!;

                let defaultCondition = 'isNotEmpty';

                if (newField.type === 'checkbox') {
                  defaultCondition = 'isChecked';
                }

                onChange({
                  idField: e.value,
                  condition: defaultCondition,
                });
              }}
            />
          </div>
          {field.conditional?.idField && (
            <div style={{ width: '100%' }}>
              <Select
                menuPlacement="top"
                isDisabled={!field.conditional?.idField}
                options={conditionOptions}
                placeholder="Condition"
                value={
                  field.conditional?.condition
                    ? conditionOptions.find(
                        (f) => f.value === field.conditional?.condition
                      )
                    : null
                }
                onChange={(e: any) => {
                  onChange({
                    ...field.conditional,
                    condition: e.value,
                  });
                }}
              />
            </div>
          )}
          {shouldShowValueInput && (
            <div style={{ width: '100%' }}>
              {valueOptions.length ? (
                <Select
                  options={valueOptions}
                  menuPlacement="top"
                  value={
                    field.conditional?.value
                      ? valueOptions.find(
                          (f: any) => f.value === field.conditional?.value
                        )
                      : null
                  }
                  onChange={(e: any) => {
                    onChange({
                      ...field.conditional,
                      value: e.value,
                    });
                  }}
                />
              ) : (
                <TextInput
                  placeholder="Value"
                  type={field.type === 'number' ? 'number' : 'text'}
                  isDisabled={!field.conditional?.condition}
                  value={field.conditional?.value || ''}
                  onChange={(e) => {
                    onChange({
                      ...field.conditional,
                      value: e.currentTarget.value,
                    });
                  }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// TODO:
/**
 * - remove allFields and create a context
 * - context should also give labels, members, and custom fields
 * - create a hook to setBump to redraw arrows
 * - implement conditional logic on the external frontend
 * - implement conditional logic on the backend
 */
