import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Textarea from '@atlaskit/textarea';
import TextInput from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import Tooltip from '@atlaskit/tooltip';
import slugify from 'slugify';
import { formsUrl } from '../../api/externalForms';

import styles from './ShareDialog.module.scss';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const ShareDialog: React.FC = () => {
  const query = useQuery();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const formId = query.get('formId');
  const formName = query.get('formName') || '';

  const shareUrl = `${formsUrl}/${formId}/${slugify(formName.substring(0, 36), {
    lower: true,
  })}`;

  return (
    <div>
      <div style={{ display: 'flex', gap: '8px', marginBottom: '8px' }}>
        <TextInput id="shareUrlInput" isReadOnly value={shareUrl} />
        <Tooltip content="Copy to clipboard" hideTooltipOnClick>
          <Button
            iconBefore={<CopyIcon label="copy" />}
            onClick={(e) => {
              const input = document.getElementById(
                'shareUrlInput'
              ) as HTMLInputElement;
              input.select();
              document.execCommand('copy');
            }}
          />
        </Tooltip>
      </div>

      <div className={styles.label}>Embed</div>
      <Textarea
        ref={textAreaRef}
        isReadOnly
        minimumRows={3}
        value={`<iframe width="560" height="600" src="${shareUrl}?embed=iframe" title="${formName}" frameborder="0"></iframe>`}
      />
      <Button
        style={{ marginTop: '8px' }}
        shouldFitContainer
        onClick={() => {
          textAreaRef.current?.select();
          document.execCommand('copy');
        }}
      >
        Copy
      </Button>
    </div>
  );
};
