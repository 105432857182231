import { Form } from './types';

const host = window.location.host;
let serverUrl = '';

switch (host) {
  case 'cdn.stg.powerups.club':
    serverUrl = 'https://api.stg.powerups.club';
    break;
  case 'cdn.powerups.club':
    serverUrl = 'https://apiv2.powerups.club';
    break;
  default:
    serverUrl = 'http://localhost:3001';
}

async function preparePayload(t: any, payload?: any) {
  const token = await t.getRestApi().getToken();

  const compressedPayload = payload
    ? window.LZUTF8.compress(JSON.stringify(payload), {
        outputEncoding: 'StorageBinaryString',
      })
    : undefined;

  const jwt = await t.jwt({
    state: JSON.stringify({
      token,
      payload: compressedPayload,
    }),
  });

  return jwt;
}

export async function getForms(t: any): Promise<Form[]> {
  const jwt = await preparePayload(t);

  const response = await fetch(`${serverUrl}/forms?jwt=${jwt}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('failed to get forms');
  }

  return response.json();
}

export async function getFormById(t: any, id: string): Promise<Form> {
  const jwt = await preparePayload(t);

  const response = await fetch(`${serverUrl}/forms/${id}?jwt=${jwt}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('failed to get form');
  }

  return response.json();
}

export async function createForm(
  t: any,
  form: Partial<Form>,
  newLogoFile?: File | null
): Promise<Form> {
  const jwt = await preparePayload(t, form);
  const response = await fetch(`${serverUrl}/forms`, {
    method: 'POST',
    body: JSON.stringify({
      jwt,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('failed to create form');
  }

  const createdForm = await response.json();

  if (createdForm.id && newLogoFile) {
    await uploadLogo(t, createdForm.id, newLogoFile);
  }

  return createdForm;
}

export async function updateForm(
  t: any,
  form: Partial<Form>,
  newLogoFile?: File | null
) {
  const jwt = await preparePayload(t, form);
  const response = await fetch(`${serverUrl}/forms/${form.id}`, {
    method: 'PUT',
    body: JSON.stringify({
      jwt,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('failed to update form');
  }

  if (form.id && newLogoFile) {
    await uploadLogo(t, form.id, newLogoFile);
  }

  return response.json();
}

export async function uploadLogo(t: any, idForm: string, logoFile: File) {
  const jwt = await preparePayload(t);
  const formData = new FormData();
  formData.append('logo', logoFile);
  formData.append('jwt', jwt);

  const response = await fetch(`${serverUrl}/forms/${idForm}/uploadLogo`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('failed to upload logo');
  }

  return response.json();
}

export async function deleteForm(t: any, idForm: string) {
  const jwt = await preparePayload(t);
  const response = await fetch(`${serverUrl}/forms/${idForm}?jwt=${jwt}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('failed to delete form');
  }

  return response.json();
}

export async function cardToFormMap(t: any) {
  const jwt = await preparePayload(t);
  const response = await fetch(`${serverUrl}/forms/cardToFormMap?jwt=${jwt}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('failed to get card to form map');
  }

  return response.json();
}

export async function isCreatedByForms(t: any, idCard: string) {
  const token = await t.getRestApi().getToken();
  window.Trello.setToken(token);

  try {
    const actions = await window.Trello.get(`/cards/${idCard}/actions`, {
      filter: 'createCard',
    }).promise();

    const createdAction = actions[0];

    return createdAction?.appCreator?.id === '63813784daa93d0195a4a438';
  } catch (error) {
    return false;
  }
}
