import {
  PaymentsContext,
  PaymentsContextProvider,
  PaymentsV3,
} from '@powerupsclub/payments';
import {
  Authorization,
  TrelloPowerUpContext,
  TrelloPowerUpContextProvider,
} from '@powerupsclub/trello';
import { FeedbackRoute } from '@powerupsclub/feedback';
import { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { FormPage, FormsPage } from './components/FormsModal';
import { IframeConnector } from './components/IframeConnector';
import { ShareDialog } from './components/ShareDialog';
import { LoggerErrorBoundary } from '@powerupsclub/logger';

const PaymentWrapper = () => {
  const { trelloIframe } = useContext(TrelloPowerUpContext);
  const { refetchSubscription } = useContext(PaymentsContext);
  const { state } = useLocation<{ from: string }>();
  const history = useHistory();

  useEffect(() => {
    trelloIframe.updateModal({
      fullscreen: true,
    });
  }, [trelloIframe]);

  return (
    <PaymentsV3
      onComplete={async () => {
        await refetchSubscription({ waitForStatus: 'active' });
        await trelloIframe.set('board', 'private', 'hasSubscription', true);
        trelloIframe.updateModal({
          fullscreen: false,
          height: 600,
        });
        history.push(state?.from || './forms');
      }}
      onDismiss={async () => {
        trelloIframe.updateModal({
          fullscreen: false,
          height: 600,
        });
        history.push(state?.from || './forms');
      }}
    />
  );
};

const AuthWrapper = () => {
  const { isAuthorized, trelloIframe } = useContext(TrelloPowerUpContext);
  const { state } = useLocation<{ from: string }>();
  const history = useHistory();

  useEffect(() => {
    if (isAuthorized) {
      history.push(state?.from || './forms');
    }
  }, [history, isAuthorized, state, trelloIframe]);

  return <Authorization heading="Forms" imageSrc="./logo.png" />;
};

function App() {
  return (
    <LoggerErrorBoundary powerUpKey="forms">
      <Router
        basename={process.env.NODE_ENV === 'development' ? undefined : '/forms'}
      >
        <Switch>
          <Route exact path="/iframeConnector" component={IframeConnector} />

          <TrelloPowerUpContextProvider
            appKey="5549551b9ed7f39a02e5918b5b01b24c"
            appName="Forms"
            powerUpKey="forms"
            localization={{
              defaultLocale: 'en',
              supportedLocales: ['en'],
              resourceUrl:
                process.env.NODE_ENV === 'development'
                  ? '/strings/{locale}.json'
                  : '/forms/strings/{locale}.json',
            }}
          >
            <PaymentsContextProvider>
              <Route exact path="/payment">
                <PaymentWrapper />
              </Route>
              <Route exact path="/authorize">
                <AuthWrapper />
              </Route>

              <FeedbackRoute />

              <Route exact path="/forms">
                <FormsPage />
              </Route>

              <Route exact path="/form">
                <FormPage />
              </Route>

              <Route exact path="/share">
                <ShareDialog />
              </Route>
            </PaymentsContextProvider>
          </TrelloPowerUpContextProvider>
        </Switch>
      </Router>
    </LoggerErrorBoundary>
  );
}

export default App;
