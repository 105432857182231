import styles from './Fields.module.scss';
import Button from '@atlaskit/button';
import Icon from '@atlaskit/icon';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TextInput from '@atlaskit/textfield';
import Textarea from '@atlaskit/textarea';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Checkbox from '@atlaskit/checkbox';
import { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { NumberField as NumberFieldType } from '../../api/types';
import { TrelloPowerUpContext } from '@powerupsclub/trello';
import { FormPageContext } from '../FormsModal/FormPage';
import { ConditionalSelection } from './ConditionalSelection';

type NumberFieldProps = {
  field: NumberFieldType;
  defaultExpanded?: boolean;
  onChange: (newField: NumberFieldType) => void;
  onRemove: () => void;
};

export const NumberField: React.FC<NumberFieldProps> = ({
  field,
  defaultExpanded,
  onChange,
  onRemove,
}) => {
  const { trelloIframe } = useContext(TrelloPowerUpContext);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { bump } = useContext(FormPageContext);

  useEffect(() => {
    bump();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const missingName = field.label.length === 0;

  return (
    <div
      id={field.id}
      className={cx(styles.fieldContainer, {
        [styles.collapsed]: !expanded,
      })}
    >
      <div className={styles.fieldHeader}>
        {expanded ? (
          <>
            <Icon
              label="number"
              glyph={() => (
                <svg
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M8.114 2.094a.75.75 0 01.386.656V9h1.252a.75.75 0 110 1.5H5.75a.75.75 0 010-1.5H7V4.103l-.853.533a.75.75 0 01-.795-1.272l2-1.25a.75.75 0 01.762-.02zm4.889 5.66a.75.75 0 01.75-.75h5.232a.75.75 0 01.53 1.28l-2.776 2.777c.55.097 1.057.253 1.492.483.905.477 1.504 1.284 1.504 2.418 0 .966-.471 1.75-1.172 2.27-.687.511-1.587.77-2.521.77-1.367 0-2.274-.528-2.667-.756a.75.75 0 01.755-1.297c.331.193.953.553 1.912.553.673 0 1.243-.188 1.627-.473.37-.275.566-.635.566-1.067 0-.5-.219-.836-.703-1.091-.538-.284-1.375-.443-2.471-.443a.75.75 0 01-.53-1.28l2.643-2.644h-3.421a.75.75 0 01-.75-.75zM7.88 15.215a1.4 1.4 0 00-1.446.83.75.75 0 01-1.37-.61 2.9 2.9 0 012.986-1.71 2.565 2.565 0 011.557.743c.434.446.685 1.058.685 1.778 0 1.641-1.254 2.437-2.12 2.986-.538.341-1.18.694-1.495 1.273H9.75a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75c0-1.799 1.337-2.63 2.243-3.21 1.032-.659 1.55-1.031 1.55-1.8 0-.355-.116-.584-.26-.732a1.068 1.068 0 00-.652-.298z"
                  />
                </svg>
              )}
            />
            <TextInput
              isInvalid={missingName}
              placeholder="Field name"
              value={field.label}
              onChange={(e) => {
                onChange({
                  ...field,
                  label: e.currentTarget.value,
                });
              }}
            />
          </>
        ) : (
          <FormNumberField field={field} isPreview />
        )}

        {!expanded && (
          <div className={styles.editActions}>
            <Button
              appearance="subtle"
              spacing="compact"
              isDisabled={missingName}
              onClick={() => setExpanded(!expanded)}
              iconBefore={<EditIcon size="small" label="edit" />}
            />

            <Button
              appearance="subtle"
              spacing="compact"
              iconBefore={<EditorCloseIcon label="remove" />}
              onClick={(e) => {
                e.stopPropagation();
                trelloIframe.popup({
                  mouseEvent: e,
                  title: 'Remove field',
                  type: 'confirm',
                  message:
                    'Are you sure you want to remove this field? There is no undo.',
                  confirmText: 'Remove',
                  confirmStyle: 'danger',
                  onConfirm: (t: any) => {
                    onRemove();
                    t.closePopup();
                  },
                });
              }}
            />
          </div>
        )}
      </div>

      {expanded && (
        <>
          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Description</div>
            <Textarea
              placeholder="Description"
              value={field.description}
              onChange={(e) => {
                onChange({
                  ...field,
                  description: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Placeholder</div>
            <TextInput
              placeholder="Placeholder"
              value={field.placeholder}
              onChange={(e) => {
                onChange({
                  ...field,
                  placeholder: e.currentTarget.value,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <div className={styles.editLabel}>Range</div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <TextInput
                placeholder="Minimum"
                type="number"
                step="any"
              ></TextInput>
              <TextInput
                placeholder="Maximum"
                type="number"
                step="any"
              ></TextInput>
            </div>
          </div>

          <div className={styles.fieldSection}>
            <Checkbox
              label="This field is required"
              isChecked={field.required}
              onChange={(e) => {
                onChange({
                  ...field,
                  required: e.currentTarget.checked,
                });
              }}
            />
          </div>

          <div className={styles.fieldSection}>
            <ConditionalSelection
              field={field}
              onChange={(conditional) => {
                onChange({
                  ...field,
                  conditional,
                });
              }}
            />
          </div>
        </>
      )}
      <div className={styles.fieldIsLinkedToText}>
        This field is linked to the card {field.targetField}.
      </div>

      {expanded && (
        <div className={styles.expandEditActions}>
          <Button
            appearance="primary"
            isDisabled={missingName}
            onClick={() => setExpanded(false)}
          >
            Done
          </Button>

          <Button
            appearance="subtle"
            onClick={(e) => {
              e.stopPropagation();
              trelloIframe.popup({
                mouseEvent: e,
                title: 'Remove field',
                type: 'confirm',
                message:
                  'Are you sure you want to remove this field? There is no undo.',
                confirmText: 'Remove',
                confirmStyle: 'danger',
                onConfirm: (t: any) => {
                  onRemove();
                  t.closePopup();
                },
              });
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

type FormNumberFieldProps = {
  field: NumberFieldType;
  isPreview?: boolean;
  fieldProps?: any;
};

export const FormNumberField: React.FC<FormNumberFieldProps> = ({
  field,
  isPreview,
  fieldProps,
}) => {
  return (
    <div className={styles.formFieldContainer}>
      <div className={styles.label}>{field.label}</div>
      {field.description && (
        <div className={styles.description}>{field.description}</div>
      )}
      <TextInput
        type="number"
        min={field.min}
        max={field.max}
        step="any"
        placeholder={field.placeholder}
        isReadOnly={isPreview}
        isRequired={field.required}
        {...(fieldProps ? fieldProps : {})}
      />
    </div>
  );
};
