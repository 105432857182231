import { Form } from './types';

const host = window.location.host;
let serverUrl = '';

switch (host) {
  case 'forms.stg.trll.io':
    serverUrl = 'https://api.stg.powerups.club';
    break;
  case 'forms.trll.io':
    serverUrl = 'https://apiv2.powerups.club';
    break;
  default:
    serverUrl = 'http://localhost:3001';
}

export const formsUrl =
  process.env.REACT_APP_FORMS_URL || 'http://localhost:3000';

export async function getForm(id: string): Promise<Form> {
  const response = await fetch(`${serverUrl}/forms/external/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('failed to get form');
  }

  return response.json();
}

export async function submitMultipartForm(
  id: string,
  formData: FormData
): Promise<{ idCard: string }> {
  const response = await fetch(`${serverUrl}/forms/external/${id}`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('failed to submit form');
  }

  return response.json();
}
