import styles from './FieldToken.module.scss';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';

type Props = {
  name: string;
  icon: React.ReactNode;
};

export const FieldToken: React.FC<Props> = ({ name, icon }) => {
  return (
    <div className={styles.fieldToken}>
      {icon}
      <span style={{ width: '100%' }}>{name}</span>
      <DragHandlerIcon label="drag handle" />
    </div>
  );
};

export const CustomFieldToken: React.FC<Props> = ({ name, icon }) => {
  return <div></div>;
};

export const EmptyCustomFieldToken: React.FC = () => {
  return (
    <div className={styles.emptyCustomFieldToken}>
      Add Custom fields to your board and they will appear here. Learn more.
    </div>
  );
};
